import React from "react";
import styles from "./safety.module.css";
import saftety from "../../media/company/companysafety.png";

const Safety = () => {
  return (
    <div className={styles.safetyParent}>
      <div className={styles.imgage}>
        <img src={saftety} alt="safety src" />
      </div>
      <div className={styles.hero}>
        <p className={styles.title}>SAFETY & SOLUTIONS</p>
        <p className={styles.desc}>
        <span className={styles.jptitle}> JP ELECTRICAL AND POWER SYSTEM</span>specializes in providing safety
          solutions for aging but highly capable industrial equipment. Rather
          than replacing functional equipment, we focus on making modifications
          that align with modern expectations, ensuring compliance and enhancing
          safety. Our Safety-First approach has resulted in numerous safety
          innovations that effectively mitigate risks and safeguard your
          workforce.
        </p>
      </div>
      <div className={styles.solutions}>
        <p className={styles.title}>
          Here are some safety solutions we provide:
        </p>
        <div className={styles.solution}>
          <div className={styles.solutionChild}>
            <p className={styles.title}>Equipment Upgrades</p>
            <p className={styles.desc}>
              Our experts pinpoint obsolete components in your electrical
              equipment that could compromise safety. We provide practical
              solutions like upgrading circuit breakers, adding ground fault
              circuit interrupters (GFCIs), or modernizing insulation systems.
              Your safety is our priority, and we're here to enhance the
              security of your electrical infrastructure.
            </p>
          </div>

          <div className={styles.solutionChild}>
            <p className={styles.title}>Preventive Maintenance</p>
            <p className={styles.desc}>
              We provide vital preventive maintenance programs comprising
              inspections, testing, cleaning, and lubrication. These proactive
              measures are crucial for ensuring electrical equipment's safe and
              reliable operation. By identifying potential hazards and
              performance issues early, we mitigate the risk of accidents and
              equipment failures.
            </p>
          </div>

          <div className={styles.solutionChild}>
            <p className={styles.title}>Safety Audits and Assessments</p>
            <p className={styles.desc}>
              Our team performs comprehensive safety audits and assessments on
              electrical systems and equipment. We assess factors such as
              electrical code compliance, grounding and bonding effectiveness,
              protective device coordination, and system reliability. Our
              recommendations, based on our findings, aim to improve safety and
              reduce risks.
            </p>
          </div>

          <div className={styles.solutionChild}>
            <p className={styles.title}>Risk Management</p>
            <p className={styles.desc}>
              We create industry-specific risk management strategies,
              customizing them to your equipment and needs. This involves hazard
              identification, safety protocols, emergency plans, and regular
              compliance checks. Our solutions reduce accidents, safeguard your
              team, and ensure modern safety standards. Connect with JP
              ELECTRICAL AND POWER SYSTEM to enhance safety and equipment
              performance.
            </p>
          </div>
        </div>

        <p className={styles.desc}>
          Our safety innovations not only remove risks and protect your people
          but also offer additional benefits such as relocating hazards to keep
          personnel out of harm’s way. Moreover, by eliminating the need for
          personal protective equipment (PPE) during the energized sampling
          process, we streamline operations, increase productivity, and reduce
          potential delays.
        </p>
      </div>

      <div className={styles.risk}>
        <p className={styles.title1}>RISKS & HAZARDS</p>
        <p className={styles.desc1}>
        <span className={styles.jptitle}> JP ELECTRICAL AND POWER SYSTEM</span>is working hard to dramatically reduce
          injuries and fatalities due to electrical inspections, and we have
          made a very solid start toward that goal.
        </p>
        <p className={styles.title2}>Steps To Eliminating Hazards.</p>
        <p className={styles.desc2}>
          For more than half a century, JP ELECTRICAL AND POWER SYSTEM has
          worked alongside commercial and industrial organizations to keep their
          electric power systems reliable, safe, and cost-effective. We have the
          expertise, the data, and the foundational understanding you need to
          complete the process from start to finish. We recommend:
        </p>

        <div className={styles.riskCards}>
          <div className={styles.card}>
            <div className={styles.cardHeading}>
              <p className={styles.cardNumber}>1</p>
              <p className={styles.cardTitle}>
                {" "}
                Know your assets, know your risk.
              </p>
            </div>
            <p className={styles.cardDesc}>
              {" "}
              Begin with a thorough inventory of your equipment, starting with
              the transmission feed, through to the substation and distribution
              system, all the way into to the 480v panels in your facility. An
              audit of your electrical assets highlights current successes and
              reveals the hazards that often remain unseen or unaddressed.
              Additionally, don’t underestimate the importance of a one-line
              diagram.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardHeading}>
              <p className={styles.cardNumber}>2</p>
              <p className={styles.cardTitle}>Implement safer processes.</p>
            </div>
            <p className={styles.cardDesc}>
              Learn and follow the guidelines for occupational safety around
              high voltage equipment. Navigating these guidelines can be complex
              but understanding the details will help you develop safer
              inspection and testing practices. Better practices mean a lower
              risk to your electrical system, your operation—and your people.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardHeading}>
              <p className={styles.cardNumber}>3</p>
              <p className={styles.cardTitle}>
                {" "}
                Prioritize the safety of your team.
              </p>
            </div>
            <p className={styles.cardDesc}>
              {" "}
              Invest in safety beyond engineering controls and PPE. Electrical
              maintenance safety devices are easily installed (and often without
              the need to de-energize) and they do more than create a
              barrier—they move humans out of harm’s way. This means that even
              if a serious incident should occur, such as an arc flash,
              everybody involved gets to go home at the end of the day.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardHeading}>
              <p className={styles.cardNumber}>4</p>
              <p className={styles.cardTitle}>
                {" "}
                Monitor and maintain your equipment.
              </p>
            </div>
            <p className={styles.cardDesc}>
              {" "}
              Commit to a reliability program. One of the tenets of the
              maintenance industry is that a “safe plant is a reliable plant.”
              Safety and reliability are two sides of the same coin. Regular
              testing and inspection of your equipment allows you to plan your
              maintenance budget and stay ahead of faults and issues, avoiding
              the risks involved with failure.
            </p>
          </div>
        </div>

        <p className={styles.riskFooter}>
          {" "}
          JP ELECTRICAL AND POWER has experts on-hand who can tailor this
          process to your business and make specific recommendations for your
          electrical equipment. Let us know if you’d like to set up a safety
          consultation.
        </p>
      </div>
    </div>
  );
};

export default Safety;
