import axios from "axios";
import { useState } from "react";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { FaCarAlt } from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { VscGraphLine } from "react-icons/vsc";
import { sendEmail } from "../../utils/email";
import MapSection from "../Map/MapSection";
  
const EnquirySection = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [service, setService] = useState("");

  const clearForm = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhoneNo("");
    setMessage("");
    setService("");
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !phoneNo.trim() ||
      !message.trim()
    )
      return toast.error("Please fill all the fields");
    const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phoneNo.match(PHONE_REGEX)) {
      setLoading(false);
      return toast.error("Please enter a valid phone number");
    }
    sendEmail({
      name: `${firstName} ${lastName}`,
      email,
      phoneNo,
      message,
      clearForm,
      service,
      setLoading,
    });
  };

  return (
    <>
      
      <div className="">
        <div
          className="py-12 flex justify-center"
          style={{
            // backgroundImage: `url(${banner})`
            backgroundColor: "#ff",
          }}
        >
          <div className="lg:px-0 max-w-[1280px] md:px-10 px-4">
            <h1 className="tracking-wider font-semibold text-3xl text-indigo-900 text-center">
              Empowering Your Electrical Solutions
            </h1>
            <div className="flex flex-col md:flex-row gap-8 mt-10">
              {/* EnquiryDetails */}
              <div className="flex flex-col  gap-10 flex-1 tracking-wider text-white text-lg md:text-lg">
                <p className="text-center md:text-left  text-indigo-900">
                  For comprehensive transformer servicing, spare parts support,
                  and reliable electrical maintenance, trust JP Electrical and
                  Power System. With transformer rental services and readily
                  available components, we ensure uninterrupted power supply for
                  various industries. Keep your operations running smoothly
                  enquiry us today!
                </p>
                {/* services */}
                <div className="flex flex-col gap-6  md:text-lg items-center md:items-start ">
                  <div className="flex gap-2 items-center bg-indigo-900 py-1 px-2 w-full max-w-[450px] rounded-md">
                    <p className="font-medium">Expertise and Specialization</p>
                  </div>
                  <div className="flex gap-2 items-center bg-indigo-900 py-1 px-2 w-full max-w-[450px] rounded-md">
                    <p className="font-medium">Reliable Support</p>
                  </div>
                  <div className="flex gap-2 items-center bg-indigo-900 py-1 px-2 w-full max-w-[450px] rounded-md">
                    <p className="font-medium">Industry Coverage</p>
                  </div>
                  <div className="flex gap-2 items-center bg-indigo-900 py-1 px-2 w-full max-w-[450px] rounded-md">
                    <p className="font-medium">Transformer Rental Services</p>
                  </div>
                </div>
                {/* services */}
              </div>
              {/* EnquiryDetails */}

              {/* EnquiryForm */}
              <div className="flex-1 flex justify-center md:justify-end">
                <form
                  className="rounded-md max-md:w-[85%] p-6 border shadow-md bg-white"
                  onSubmit={handleSubmit}
                >
                  <div className="">
                    <div className="">
                      <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            First name
                          </label>
                          <div className="mt-1">
                            <input
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              type="text"
                              name="first-name"
                              id="first-name"
                              className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  border focus:outline-none sm:text-sm sm:leading-6 px-2"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Last name
                          </label>
                          <div className="mt-1">
                            <input
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              type="text"
                              name="last-name"
                              id="last-name"
                              className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 border focus:outline-none  sm:text-sm sm:leading-6 px-2"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-full">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              id="email"
                              name="email"
                              type="email"
                              className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  border focus:outline-none sm:text-sm sm:leading-6 px-2"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-full">
                          <label
                            htmlFor="number"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Mobile Number
                          </label>
                          <div className="mt-1">
                            <input
                              value={phoneNo}
                              onChange={(e) => setPhoneNo(e.target.value)}
                              id="number"
                              name="phone"
                              type="number"
                              className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  border focus:outline-none sm:text-sm sm:leading-6 px-2"
                            />
                          </div>
                        </div>

                        {/* ......choose */}
                        <div className="sm:col-span-full">
                          <label
                            htmlFor="number"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Choose Service
                          </label>
                          <div className="mt-1">
                            <select
                              required
                              value={service}
                              onChange={(e) => setService(e.target.value)}
                              id="number"
                              name="phone"
                              className="block w-full rounded-md   py-1.5 bg-white text-gray-900 shadow-sm  placeholder:text-gray-400  border focus:outline-none sm:text-sm sm:leading-6 px-2"
                            >
                              <option className="" value="">
                                select
                              </option>
                              <option
                                className="serviceOptions"
                                value="service"
                              >
                                Service
                              </option>
                              <option
                                className="serviceOptions"
                                value="automation"
                              >
                                Automation
                              </option>
                            </select>
                          </div>
                        </div>
                        {/* ......choose */}

                        <div className="col-span-full">
                          <label
                            htmlFor="street-address"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Message
                          </label>
                          <div className="mt-1">
                            <textarea
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              rows={3}
                              type="text"
                              name="street-address"
                              id="street-address"
                              className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 border focus:outline-none  sm:text-sm sm:leading-6 px-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-start gap-x-6">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="disabled:opacity-60 rounded-md bg-indigo-900 px-5 w-full py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700"
                    >
                      {isLoading ? (
                        <PulseLoader size={10} color="#ffffff" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
              {/* EnquiryForm */}
            </div>
          </div>
        </div>
        <MapSection />
      </div>
    </>
  );
};

export default EnquirySection;
