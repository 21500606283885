import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Enquire from "../../component/enquiry/Enquire";

const SingleCategory = ({ category }) => {
  const navigate = useNavigate();
  return (
    <div className="border w-full max-w-6xl rounded-t-lg shadow-md bg-white">
      <div className="flex flex-col md:flex-row gap-10 p-2">
        {/* imagesection */}
        <div
          onClick={() => navigate(`/services/details/${category.id}`)}
          className="bg-slate-100 w-full md:w-[50%] lg:w-[40%] h-80 flex flex-col justify-between p-1 rounded-md hover:bg-indigo-900 hover:text-white cursor-pointer"
        >
          <div className="bg-white h-[100%] flex items-center">
            <img
              className="object-contain w-full h-full"
              src={category.img}
              alt=""
            />
          </div>
          {/* <div className="text-center">({category.no})</div> */}
        </div>
        {/* imagesection */}
        {/* ContentSection */}
        <div className=" w-full py-2 flex flex-col justify-between ">
          <div className="flex justify-center gap-2">
            <Link
              to={`/services/details/${category.id}`}
              className=" text-indigo-900 text-lg cursor-pointer hover:text-black"
            >
              {category.name}
            </Link>
            {/* <button className="rounded-sm px-2  md:px-4 md:py-2 border text-sm md:text-[15px] border-blue-700 text-blue-800 hover:bg-blue-800 hover:text-white">
              REQUEST TO CALL BACK
            </button> */}
          </div>

          {/* List Section */}
          {category.price ? (
            <div className="mt-2 text-sm md:text-[15px] text-slate-700">
              <div className="border-b p-2">
                <span>
                  <h1 className="text-lg font-semibold text-indigo-900">
                    Price: {category.price}
                  </h1>
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mt-2 text-sm md:text-[15px]">
            {category.deliveryTime || category.supplyAbility ? (
              <div className="border-b flex p-2 justify-between">
                <div>
                  Delivery Time:{" "}
                  <span className="text-slate-700">
                    {category.deliveryTime}
                  </span>
                </div>
                {category.supplyAbility ? (
                  <div>
                    Supply Ability:{" "}
                    <span className="text-slate-700">
                      {category.supplyAbility}
                    </span>{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {/* List Section */}
          {/* Button */}
          <div className="flex justify-center ">
            <Enquire className={'rounded-sm px-4 py-2 bg-indigo-900 text-white text-sm md:text-[15px] mt-4   hover:bg-indigo-700'} productName={category.name}/>
          </div>
          {/* Button */}
        </div>
        {/* ContentSection */}
      </div>
    </div>
  );
};

export default SingleCategory;
