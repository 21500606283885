import React from "react";
import styles from "./Aboutus.module.css";
import aboutusImg1 from "../media/Aboutus/about us.jpg";
import aboutusImg2 from "../media/Aboutus/6.jpg";
import aboutusImg3 from "../media/Aboutus/4.jpg";
import user1 from "../media/Aboutus/user1.jpg";
const Aboutus = () => {
  return (
    <>
      <div className={styles.child}>
        <img src={aboutusImg1} alt="about us img" className="w-full" />
      </div>
      <div className={styles.AboutusParent}>
        <div className="max-w-[1280px] md:px-10 lg:px-0">
          <div className={styles.child}>
            <p className={styles.title1}>Who We Are</p>
            <p>
              With a rich legacy spanning over 15 years,
              <span className={styles.jptitle}>
                {" "}
                JP ELECTRICAL AND POWER SYSTEM
              </span>{" "}
              has solidified its position as a prominent player in the
              transformer asset and life-cycle management industry. Our
              unwavering dedication and commitment to delivering top-notch
              transformers and driving innovation in the electricity sector have
              earned us the respect and admiration of not only our clients but
              also our competitors.
            </p>

            {/* <p>
              Our unwavering dedication and commitment to delivering top-notch
              transformers and driving innovation in the electricity sector have
              earned us the respect and admiration of not only our clients but
              also our competitors.
            </p> */}

            <p className={styles.title2}> All About Today?</p>
            <p>
              <span className={styles.jptitle}>
                {" "}
                JP ELECTRICAL AND POWER SYSTEM
              </span>{" "}
              is a dynamic company that goes beyond traditional service
              providers in the field of transformers. We are committed to
              serving our customers and acting as their trusted advocate. Our
              expertise lies in a wide range of electrical and power systems,
              allowing us to provide comprehensive solutions tailored to meet
              the unique needs of our clients. Our team consists of highly
              skilled professionals who possess extensive knowledge and
              experience in the industry. We stay up to date with the latest
              advancements and technologies, ensuring that we deliver
              cutting-edge solutions that optimize efficiency, reliability, and
              safety.
            </p>
          </div>

          <div className={`${styles.child}  ${styles.imgAdd}`}>
            <p className={styles.title1}>What We Do</p>
            <p>
              At{" "}
              <span className={styles.jptitle}>
                {" "}
                JP ELECTRICAL AND POWER SYSTEM
              </span>
              , we specialize in comprehensive transformer property and
              lifecycle system solutions. Our expertise and services are
              designed to meet the diverse needs of our customers throughout the
              power industry.
            </p>
            <div className={styles.imageWrap}>
              <div className="md:pr-[50px] lg:pr-[50px] flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span className={styles.title2}> Transformer Solutions </span>
                  <p>
                    We offer a range of high quality transformers for a variety
                    of industries and applications. From design-build to
                    installation and maintenance, our solutions are built with
                    precision and reliability at their core.
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <span className={styles.title2}> Property Management </span>
                  <p>
                    Our comprehensive asset management services ensure
                    uninterrupted and durable transformer operation. We use
                    proactive strategies including condition assessments,
                    maintenance plans and life extension programs to optimize
                    asset performance
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <span className={styles.title2}>
                    {" "}
                    Innovation and Technology{" "}
                  </span>
                  <p>
                    Our dedicated team of experts provide consulting services,
                    and guide clients through the complex transformer
                    application process. We offer flexible solutions and ongoing
                    support, ensuring efficiency and a significant return on
                    investment.
                  </p>
                </div>
              </div>
              <div>
                <img
                  src={aboutusImg2}
                  alt=""
                  // className="md:h-[400px] lg:min-w-[450px] h-full w-full"
                  className="md:h-[400px] md:min-w-[450px] w-full"
                  srcset=""
                />
              </div>
            </div>
          </div>

          <div className={`${styles.child}  ${styles.imgAdd}`}>
            <p className={styles.title1}>Saftey & Solutions</p>
            <p>
              <span className={styles.jptitle}>
                {" "}
                JP ELECTRICAL AND POWER SYSTEM
              </span>{" "}
              specializes in providing safety solutions for aging but highly
              capable industrial equipment. Rather than replacing functional
              equipment, we focus on making modifications that align with modern
              expectations, ensuring compliance and enhancing safety. Our
              Safety-First approach has resulted in numerous safety innovations
              that effectively mitigate risks and safeguard your workforce.
            </p>

            <div>
              <div className={styles.imageWrap}>
                <div className="md:pr-[50px] lg:pr-[50px] flex flex-col gap-3">
                  <div className="flex flex-col gap-2">
                    <span className={styles.title2}> Equipment Upgrades</span>
                    <p>
                      We help you identify outdated components or systems in
                      your electrical equipment that may pose safety risks. Our
                      experts can recommend and implement upgrades to improve
                      safety features, such as replacing old circuit breakers,
                      installing ground fault circuit interrupters (GFCIs), or
                      updating insulation systems.
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className={styles.title2}>
                      {" "}
                      Preventive Maintenance{" "}
                    </span>
                    <p>
                      Regular maintenance is essential to ensure the safe and
                      reliable operation of electrical equipment. We offer
                      comprehensive preventive maintenance programs that include
                      inspections, testing, cleaning, and lubrication. This
                      proactive approach helps identify potential hazards or
                      performance issues before they lead to accidents or
                      equipment failures.
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className={styles.title2}>
                      {" "}
                      Safety Audits and Assessments{" "}
                    </span>
                    <p>
                      Our team conducts thorough safety audits and assessments
                      of your electrical systems and equipment. We evaluate
                      factors like electrical code compliance, grounding and
                      bonding effectiveness, protective device coordination, and
                      overall system reliability. Based on the findings, we
                      provide recommendations to enhance safety and mitigate
                      risks.
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    src={"/about/about-saftey.jpg"}
                    alt=""
                    // className="lg:max-h-[700px] h-full w-full"
                    className="md:h-[400px]  md:min-w-[450px] w-full"
                    srcset=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
