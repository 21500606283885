import axios from "axios";
import { toast } from "react-toastify";

export const sendEmail = ({
  name,
  email,
  message,
  phoneNo,
  clearForm,
  service,
  setLoading,
  setOpen
}) => {
  const data = {
    service_id: process.env.REACT_APP_SERVICE_ID,
    template_id: process.env.REACT_APP_TEMPLATE_ID,
    user_id: process.env.REACT_APP_USER_ID,

    template_params: {
      from_name: name,
      from_email: email,
      message: message,
      user_phone: phoneNo,
      user_email: email,
      reply_to: email,
      service: service,
    },
  };

  axios
    .post(process.env.REACT_APP_EMAIL_URL, data)
    .then(() => {
      toast.info("Your message has been successfully submitted");
      clearForm();
      setLoading(false)
      if(setOpen){
        setOpen(false)
      }
    })
    .catch((err) => {
      console.log(err, "the error");
      toast.error("Error sending your message!");
      setLoading(false)
    });
};
