import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { sendEmail } from "../../utils/email";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const Enquire = ({ className, productName }) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const clearForm = () => {
    setEmail("");
    setPhoneNo("");
    setMessage("");
    setName("");
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (!name.trim() || !email.trim() || !phoneNo.trim() || !message.trim()) {
      toast.error("Please fill all the fields");
      setLoading(false);
      return;
    }
    const PHONE_REGEX=/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    if(!phoneNo.match(PHONE_REGEX)){
      setLoading(false)
      return toast.error('Please enter a valid phone number')}
    sendEmail({
      name: name,
      email,
      phoneNo,
      message,
      clearForm,
      service: productName,
      setLoading,
      setOpen
    });
    
    // setOpen(false);
  };

  
  return (
    <div>
      <button onClick={onOpenModal} className={className}>
        Send Inquiry
      </button>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="md:w-[500px] lg:px-8">
          <div className="">
            <h2 className="mt-5 text-center text-xl font-bold leading-9 tracking-tight text-gray-700">
              <span className="font-normal">Enquiry for </span>
              {productName}
            </h2>
          </div>

          <div className="mt-2 w-full">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <div className="mt-2">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    type="text"
                    placeholder="Your name"
                    className="outline-none w-full px-3 py-3 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-2">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                    placeholder="Email Id"
                    className="outline-none w-full px-3 py-3 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <div className="mt-2">
                  <input
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    required
                    type="number"
                    placeholder="Mobile number"
                    className="outline-none w-full px-3 py-3 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <div className="mt-2">
                  <textarea
                    placeholder="* To get accurate quotes. Please include product name, order quantity, usage, special requests if any in your inquiry."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    rows={3}
                    className="max-sm:placeholder:text-sm outline-none w-full px-3 py-3 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-900 px-3 py-2 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-800"
                >
                  {isLoading ? <BeatLoader color="white" /> : "Send Inquiry"}
                </button>
              </div>
            </form>

            <p
              onClick={onCloseModal}
              className="mt-5 cursor-pointer text-center text-sm hover:underline text-gray-500"
            >
              Cancel
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Enquire;
