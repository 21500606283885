import React from "react";
import styles from "./RefurbushmentAndOverHouling.module.css";
import headerImg from "../../../media/NavTransformer/overhauling/Refurbishment and overhauling.jpg";
import card1Img from "../../../media/NavTransformer/overhauling/side card img2.jpg";
// cards img
import card2Img from "../../../media/NavTransformer/overhauling/cardimg (1).png";
import card3Img from "../../../media/NavTransformer/overhauling/cardimg (2).png";
import card4Img from "../../../media/NavTransformer/overhauling/cardimg (3).png";
import card5Img from "../../../media/NavTransformer/overhauling/cardimg (4).png";
import card6Img from "../../../media/NavTransformer/overhauling/cardimg (5).png";
import card7Img from "../../../media/NavTransformer/overhauling/cardimg (6).png";
import { Link } from "react-router-dom";

const RefurbushmentAndOverhouling = () => {
  return (
    <>
    <div className={styles.images}>
        <img className="w-full" src={headerImg} alt="image" />
      </div>
    <div className={styles.parent}>
      <div className="max-w-[1280px]">

      <div className={styles.child1}>
        <p className={styles.header}>
          TRANSFORMER REFURBISHMENT AND OVERHAULING
        </p>
        <div className={`${styles.info}`}>
          <p className={`${styles.desc}`}>
            The on-site refurbishment programme provided by  <span className={styles.jptitle}> JP ELECTRICAL AND POWER SYSTEM</span> is particularly suitable for organizations that cannot afford
            to take their assets offline for an extended period. This program
            offers an effective solution while minimizing outage and disruption.
            In cases where the transformer is structurally sound but requires
            servicing and upgrading of ancillary components, this service is
            highly beneficial. The program typically involves tasks such as
            cooler bank replacements, re-gasketing units, and painting the
            transformer's exterior. Following these refurbishment activities,
            the unit is thoroughly tested on-site and subsequently
            re-commissioned.
          </p>
          <div className={styles.image}>
            <img src={card1Img} className="" alt="card img" />
          </div>
        </div>
      </div>

      <div className={styles.child2}>
        <p className={styles.heading}>
          Transformer Refurbishment and Overhauling Services
        </p>

        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.cardimg}>
              <img src={card2Img} alt="cabinet" />
            </div>
            <h3>Appropriate Diagnostic Criteria</h3>
            <p className={styles.cardDesc}>
              Our process begins with a thorough inspection of your transformer.
              We carry out detailed inspections, examining insulation, windings,
              cores and accessories to identify areas in need of repair or
              refurbishment.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardimg}>
              <img src={card3Img} alt="cabinet" />
            </div>
            <h3>Core and Coil Resurrection</h3>
            <p className={styles.cardDesc}>
              Our experienced technicians are experts in the proper repair of
              transformer cores and coils. This includes repairing damaged
              windings, replacing damaged insulation and restoring the core to
              peak condition, significantly improving overall performance
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardimg}>
              <img src={card4Img} alt="cabinet" />
            </div>
            <h3>Advanced Oil Removal and Maintenance</h3>
            <p className={styles.cardDesc}>
              We prioritize your transformer’s most important protective and
              cooling—and oil—health. Our advanced oil filtration and cleaning
              processes remove impurities, keep the oil fresh and ensure it
              exceeds industry standards for enhanced performance.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardimg}>
              <img src={card5Img} alt="cabinet" />
            </div>
            <h3>Gasket Replacement and Installation Integrity</h3>
            <p className={styles.cardDesc}>
              To secure your transformer against leaks and preserve its
              integrity, we professionally replace damaged gaskets and seals.
              This important step prevents water leakage, maintains protective
              properties, and ensures long-term reliability.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardimg}>
              <img src={card6Img} alt="cabinet" />
            </div>
            <h3>Modern Production and Innovation</h3>
            <p className={styles.cardDesc}>
              Use our modern and upgraded services to move your transformers
              into the digital age. We inspect your transformer for
              compatibility with state-of-the-art technology and implement
              upgrades to improve efficiency, maintenance and overall
              performance.
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.cardimg}>
              <img src={card2Img} alt="cabinet" />
            </div>
            <h3>Comprehensive Testing and Best Practices</h3>
            <p className={styles.cardDesc}>
              Before returning refurbished transformers to service, we conduct
              rigorous testing, including insulation resistance, turn ratio, and
              weight loss tests. Our unwavering commitment to quality control
              ensures that your transformers not only meet industry standards
              but exceed them.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <p>
          Transformer refurbishment and overhauling are essential for
          maintaining a reliable and efficient power distribution system. At<span className={styles.jptitle}> JP ELECTRICAL AND POWER SYSTEM</span>, we are committed to providing top-notch services
          that will extend the life of your transformers, ensuring uninterrupted
          power supply and cost savings.  <span className={styles.jptitle}> Enquire Us </span> today to discuss your
          transformer refurbishment and overhauling needs and let us help you
          maximize the performance and longevity of your critical assets.
        </p>
        <div className="mt-5">
          <Link to="/contact">Enquiry</Link>
        </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default RefurbushmentAndOverhouling;
