import React from "react";
import styles from "./RepairAndMaintanace.module.css";

import headerImg from "../../../media/NavTransformer/repaire and maintainance/field repairs and maintenance.jpg";
import card1Img from "../../../media/NavTransformer/repaire and maintainance/TRANSFORMER FIELD REPAIRS & MAINTENANCE.jpg";
import { Link } from "react-router-dom";

const RepairAndMaintanace = () => {
  return (
    <>
      <div className={styles.images}>
        <img src={headerImg} alt="image" className="w-full"/>
      </div>

      <div className={styles.parent}>
        <div className="max-w-[1280px]">
        <div className={styles.child1}>
          <p className={styles.header}>
            TRANSFORMER FIELD REPAIRS & MAINTENANCE
          </p>
          <div className={styles.info}>
            <p className={`${styles.desc}`}>
              Implementing condition-based maintenance and preventive actions is
              crucial for ensuring the reliable operation of transformers.{" "}
              <span className={styles.jptitle}>
                {" "}
                JP ELECTRICAL AND POWER SYSTEM
              </span>{" "}
              Field Services excels in this area, equipped with extensive
              knowledge, specialized equipment, reliable suppliers, and
              experienced personnel. Customized Care Plans Understanding that
              each transformer has its own story, we offer customized care
              plans. Tailored to the unique demands of your transformers, our
              maintenance schedules are designed to extend their lifespan and
              enhance their performance.Sustainable Solutions In line with our
              commitment to the environment, our maintenance services also focus
              on reducing the ecological footprint of transformers. We advocate
              for the use of biodegradable oils and promote recycling practices
              for parts and materials.
            </p>
            <div className={styles.image}>
              <img src={card1Img} alt="card img" />
            </div>
          </div>
        </div>

        <div className={styles.child2}>
          <p className={styles.heading}>
            Our Comprehensive Approach to Transformer Field Repairs and
            Maintenance Includes the following steps :
          </p>

          <div className={styles.cards}>
            <div className={styles.card}>
              <div className={styles.header}>
                <p className={styles.title}>Trend Condition</p>
              </div>
              <p className={styles.desc}>
                We continuously monitor the performance and condition of
                transformers using advanced monitoring systems and techniques.
                This allows us to analyze data and identify any deviations or
                trends that may indicate potential faults.
              </p>
            </div>

            <div className={styles.card}>
              <div className={styles.header}>
                <p className={styles.title}>Identify Fault</p>
              </div>
              <p className={styles.desc}>
                Once a deviation or trend is detected, our experts thoroughly
                analyze the data to identify the specific fault or issue present
                in the transformer. This step involves careful examination of
                various parameters such as temperature, oil quality, electrical
                measurements, and other relevant indicators.
              </p>
            </div>

            <div className={styles.card}>
              <div className={styles.header}>
                <p className={styles.title}>Diagnose Cause</p>
              </div>
              <p className={styles.desc}>
                After pinpointing the fault, our experienced personnel conduct a
                detailed diagnosis to determine the underlying cause. This
                involves a combination of analysis, testing, and inspections to
                identify factors contributing to the fault, such as insulation
                degradation, winding issues, cooling system problems, or other
                potential issues.
              </p>
            </div>

            <div className={styles.card}>
              <div className={styles.header}>
                <p className={styles.title}>Attend at Inception</p>
              </div>
              <p className={styles.desc}>
                Once the cause of the fault is determined, we take immediate
                action to rectify the problem. Our skilled technicians and
                engineers are equipped with the necessary tools and expertise to
                perform repairs on transformers from almost any manufacturer. We
                ensure a swift response to address the identified issue at its
                early stages to prevent further deterioration.
              </p>
            </div>

            <div className={styles.card}>
              <div className={styles.header}>
                <p className={styles.title}>Prevent Breakdown</p>
              </div>
              <p className={styles.desc}>
                Our proactive approach aims to prevent breakdowns by
                implementing preventive actions based on the identified fault
                and its root cause. This may involve replacing faulty
                components, enhancing insulation systems, optimizing cooling
                mechanisms, or other necessary measures. By taking preventive
                actions, we reduce the risk of future failures and extend the
                operational life of the transformer.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <p>
            Transformer refurbishment and overhauling are essential for
            maintaining a reliable and efficient power distribution system. At{" "}
            <span className={styles.jptitle}>
              {" "}
              JP ELECTRICAL AND POWER SYSTEM
            </span>{" "}
            , we are committed to providing top-notch services that will extend
            the life of your transformers, ensuring uninterrupted power supply
            and cost savings.{" "}
            <span className={styles.jptitle}> Enquire Us </span> today to
            discuss your transformer refurbishment and overhauling needs and let
            us help you maximize the performance and longevity of your critical
            assets.
          </p>
          <div className="mt-5">
            <Link to="/contact">Enquiry</Link>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default RepairAndMaintanace;
