import max1img from "../../src/component/media/NavTransformer/FiledService/productivity.png";
import max2img from "../../src/component/media/NavTransformer/FiledService/efficiency.png";
import max3img from "../../src/component/media/NavTransformer/FiledService/aassurance.png";
export const reviews = [
  {
    name: "Amit Patel",
    work: "Siddharth Engineering Works",
    feedBack:
      "Exceptional service and expertise! Prompt and efficient transformer installation. Their automation solutions modernized our processes, boosting productivity. The team was knowledgeable, responsive, and a pleasure to work with. Highly recommended for reliable electrical and automation services.",
  },
  {
    name: "Rajesh Singh",
    work: "Aruna Foods and Beverages",
    feedBack:
      "Highly impressed by their professionalism and efficiency. The seamless transformer installation and revolutionary automation services have transformed our workflow. The team exhibited excellent technical skills and offered tailored solutions. A trusted partner for all electrical and automation needs.",
  },
  {
    name: "Arjun Mehta",
    work: "Surya Steel Works",
    feedBack:
      "Exceptional service from start to finish! Precision in transformer installation and optimized operations with their automation solutions. Excellent guidance ensured all requirements were met. Thoroughly pleased with outstanding results and professionalism displayed throughout.",
  },
  {
    name: "Deepika Malhotra",
    work: "Gagan Motors Pvt. Ltd.",
    feedBack:
      "Outstanding workmanship and dedication to excellence. Flawless transformer installation and enhanced production capabilities through their automation services. Proactive team offering innovative solutions. Highly recommended for professionalism and expertise.",
  },
  {
    name: "Vivek Singhania",
    work: "Mangal Electronics Pvt. Ltd.",
    feedBack:
      "Impressive performance and reliability. Transformer installation completed ahead of schedule, and automation solutions exceeded expectations. They delivered a seamless experience with valuable insights. A truly dependable partner for electrical and automation services.",
  },
];

export const keyFeatures = [
  {
    img: "/feild-service/In depth diagnostic testing.png",
    title: "In-Depth Diagnostic Testing",
    desc: "We scrutinize transformers and use advanced diagnostic tests such as DGA, thermography and partial discharge measurements to assess their operational health.",
  },
  {
    img: "/feild-service/Performance Research.png",
    title: "Performance Research",
    desc: "Our evaluation examines various aspects of performance such as efficiency, load capacity, insulation condition and overall reliability.",
  },
  {
    img: "/feild-service/Customized Solutions.png",
    title: "Customized Suggestions",
    desc: "Based on our findings, we provide recommendations designed to improve transformer efficiency, increase efficiency, and extend service life",
  },
];

export const maxFreeBenefits = [
  {
    img: max1img,
    title: "Enhanced Productivity",
    desc: "Identify areas of inefficiency and areas that need to be improved to improve transformer efficiency.",
  },
  {
    img: max2img,
    title: "Cost Efficiency",
    desc: "Optimize transformer efficiency to reduce operating costs and avoid premature replacement.",
  },
  {
    img: max3img,
    title: "Reliability Assurance",
    desc: "Ensure reliability and security by addressing potential issues before they escalate.",
  },
];
