import React from "react";

const MapSection = () => {
  return (
    <div className="flex justify-center">
       <div className="lg:px-0 max-w-[1280px] w-full md:px-10 px-4">
        <iframe
          src={process.env.REACT_APP_LOCATION_MAP}
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default MapSection;
