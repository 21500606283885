import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const SingleService = ({ service }) => {
  const navigate = useNavigate();

  return (
    <div className="border w-full max-w-6xl rounded-t-lg   shadow-md bg-white">
      <div className="flex flex-col md:flex-row gap-10 p-2">
        {/* imagesection */}
        <div
          onClick={() =>
            service?.categories
              ? navigate(`/services/categories/${service.id}`)
              : navigate(`/services/details/${service.id}`)
          }
          className="bg-slate-100 w-full md:w-[50%] lg:w-[40%] h-80 flex flex-col justify-between p-1 rounded-md hover:bg-indigo-900 hover:text-white cursor-pointer"
        >
          <div className="bg-white h-[100%] flex items-center">
            <img
              className="object-contain w-full h-full"
              src={service.img}
              alt=""
            />
          </div>
          {/* <div className="text-center">({service.no})</div> */}
        </div>
        {/* imagesection */}
        {/* ContentSection */}
        <div className=" w-full">
          {service.categories ? (
            <Link
              to={`/services/categories/${service.id}`}
              className="font-semibold text-indigo-900 text-lg cursor-pointer hover:text-black"
            >
              {service.name}
            </Link>
          ) : (
            <Link
              to={`/services/details/${service.id}`}
              className="font-semibold text-indigo-900 text-lg cursor-pointer hover:text-black"
            >
              {service.name}
            </Link>
          )}
          <hr className="border-indigo-900 mt-1" />
          {/* List Section */}
          <div className="mt-2 text-sm md:text-[15px] text-slate-700">
            {service.categories?.map((cat) => {
              return (
                <div key={cat} className="border-b p-2">
                  <ul className="flex gap-6 lg:max-w-[450px] list-image-[url(https://tiimg.tistatic.com/catalogs/template99479/cate-icon.png)]">
                    {cat[0]?.name ? (
                      <Link
                        to={`/services/details/${cat[0].id}`}
                        className="hover:underline w-[50%] hover:text-indigo-900 cursor-pointer"
                      >
                        {cat[0].name}
                      </Link>
                    ) : (
                      ""
                    )}
                    {cat[1]?.name ? (
                      <Link
                        to={`/services/details/${cat[1].id}`}
                        className="hover:underline w-[50%] cursor-pointer hover:text-indigo-900"
                      >
                        {cat[1].name}
                      </Link>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              );
            })}
          </div>
          {/* List Section */}
          {/* Button */}
          <div className="flex justify-end mt-3">
            <button
              onClick={() =>
                service.categories
                  ? navigate(`/services/categories/${service.id}`)
                  : navigate(`/services/details/${service.id}`)
              }
              className="rounded-sm px-4 py-2 bg-indigo-900 text-white font-semibold hover:bg-indigo-700"
            >
              More...
            </button>
          </div>
          {/* Button */}
        </div>
        {/* ContentSection */}
      </div>
    </div>
  );
};

export default SingleService;
