import React from "react";
import styles from "./seviceCard.module.css";
import { Link } from "react-router-dom";
const SeviceCard = ({ img, title, desc, link }) => {
  return (
    <div className={styles.CardParent}>
      <img className={styles.img11} src={img} alt="card img1" />
      <div className={styles.cardDesc}>
        <p className={styles.cardTitle}>{title.toLowerCase()}</p>
        <p className={styles.cardDescription}>{desc}</p>
        <Link to={link} className={styles.explore}>
          Explore
        </Link>
      </div>
    </div>
  );
};

export default SeviceCard;
