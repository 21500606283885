import React, { useEffect, useState } from "react";
import productData from "../../data/products/products.json";
import { useParams } from "react-router-dom";
import SingleCategory from "../categories/SingleCategory";
const Products = () => {
  const [products, setProducts] = useState(productData);
  const { keyWord } = useParams();
  useEffect(() => {
    const filteredData = productData?.filter((prod) =>
      prod.name.toLocaleLowerCase().includes(keyWord.trim().toLocaleLowerCase())
    );
    setProducts(filteredData);
  }, [productData, keyWord]);
  return (
    <div>
      <div className="my-12 text-center">
        <h1 className="text-xl font-semibold text-slate-500">
          {!products.length
            ? `Sorry! No search results found for keyword '${keyWord}'`
            : `${products.length} Search results found for keyword '${keyWord}'`}
        </h1>
      </div>
      <div className="flex flex-col gap-6 justify-center items-center">
        {products.map((category) => {
          return <SingleCategory key={category.name} category={category} />;
        })}
      </div>
    </div>
  );
};

export default Products;
