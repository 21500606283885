import React from "react";
import styles from "./HvLv.module.css";

import headerImg from "../../../media/NavTransformer/overhauling/HV and LV electrical testing.jpg";
import card1Img from "../../../media/NavTransformer/HvLv/TRANSFORMERS HV & LV ELECTRICAL TESTING (1).jpg";
// cards img
import card2Img from "../../../media/NavTransformer/overhauling/cardimg (1).png";
import card3Img from "../../../media/NavTransformer/overhauling/cardimg (2).png";
import card4Img from "../../../media/NavTransformer/overhauling/cardimg (3).png";
import card5Img from "../../../media/NavTransformer/overhauling/cardimg (4).png";
import card6Img from "../../../media/NavTransformer/overhauling/cardimg (5).png";
import card7Img from "../../../media/NavTransformer/overhauling/cardimg (6).png";

// icon
 
const HVLvTesting = () => {
  return (
    <>
    <div className={styles.images}>
        <img src={headerImg} alt="image" className="w-full" />
      </div>
    <div className={styles.parent}>
    <div className="max-w-[1280px]">


      <div className={styles.child1}>
        <p className={styles.header}>TRANSFORMERS HV & LV ELECTRICAL TESTING</p>
        <p className={styles.subheading}>
          Non-Destructive Analysis | Offline Testing | RLA Study
        </p>
        <div className={styles.info}>
          <p className={`${styles.desc}`}>
            <span className={styles.jptitle}>
              {" "}
              JP ELECTRICAL AND POWER SYSTEM
            </span>{" "}
            &nbsp; performs on-site inspections and examines transformer assets
            as part of regular maintenance, proactive routine, and detailed
            fault-finding inspections regular inspections and tests in our
            annual maintenance contracts (AMCs), gather detailed information it
            covers electrical equipment aging, condition, and deterioration
            mouth management and includes. In the realm of electrical power
            systems, the integrity of High Voltage (HV) and Low Voltage (LV)
            transformers is paramount. The  <span className={styles.jptitle}>
              {" "}
              JP ELECTRICAL AND POWER SYSTEM
            </span>{" "}
            &nbsp; provides
            comprehensive HV & LV electrical testing services to ensure your
            transformers meet operational standards and safety regulations.
          </p>
          <div className={styles.image}>
            <img src={card1Img} alt="card img" />
          </div>
        </div>
      </div>

      <div className={`${styles.child2} mt-8`}>
        <p className={styles.heading}>
          Transformer HV & LV Electric Testing Services
        </p>

        <div className={styles.cards}>
          <div className={styles.card}>
            <h3>Visual Inspection</h3>
            <p className={styles.cardDesc}>
              Inspect the transformer for physical damage or abnormalities.
              Inspect the cooling system, bushings, and other external
              components.
            </p>
          </div>

          <div className={styles.card}>
            <h3>Dielectric Strength Test</h3>
            <p className={styles.cardDesc}>
              Perform dielectric strength tests on the insulation to ensure it
              can withstand high voltages. Test the insulation's resistance for
              any possible weaknesses.
            </p>
          </div>

          <div className={styles.card}>
            <h3>Winding Resistance Measurements</h3>
            <p className={styles.cardDesc}>
              Measure the resistance of transformer windings to ensure they meet
              design specifications. Identify any abnormalities that may affect
              performance.
            </p>
          </div>

          <div className={styles.card}>
            <h3>Insulation Power Factor Test</h3>
            <p className={styles.cardDesc}>
              Check insulation quality by measuring the power factor. Identify
              any damage to the insulation that could lead to future failure.
            </p>
          </div>

          <div className={styles.card}>
            <h3>Partial Leakage Measurements</h3>
            <p className={styles.cardDesc}>
              Perform partial leak tests to determine any localized insulation
              damage. Identify possible issues that may not be apparent in other
              tests.
            </p>
          </div>

          <div className={styles.card}>
            <h3>Oil Quality Analysis</h3>
            <p className={styles.cardDesc}>
              Transformer oil was sampled and analyzed for quality control. Look
              for contamination and damage that may affect performance.
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>
  </>
  );
};

export default HVLvTesting;
