import React from "react";
import classes from "./carousel.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import img1 from "../media/carousel/1.jpg";
import img2 from "../media/carousel/2.jpg";
import img3 from "../media/carousel/3.jpg";
import img4 from "../media/carousel/4.jpg";
import img5 from "../media/carousel/5.jpg";

const Carousel1 = () => {
  return (
    <div className={classes.carousel}>
      <Carousel showThumbs={false}  autoPlay={true} infiniteLoop={true}>
        <div>
          <img className={classes.carouselimg} src={img1} alt="img1" />
        </div>
        <div>
          <img className={classes.carouselimg} src={img2} alt="img2" />
          
        </div>
        <div>
          <img src={img3} className={classes.carouselimg} alt="img3" />
          
        </div>
        <div>
          <img src={img4} className={classes.carouselimg} alt="img3" />
          
        </div>
        <div>
          <img src={img5} className={classes.carouselimg} alt="img3" />
          
        </div>
      </Carousel>
    </div>
  );
};

export default Carousel1;
