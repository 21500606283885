import { Outlet } from "react-router-dom";
import Footer from "./component/footer/Footer";
import Navbar from "./component/navbar/Navbar";
import styles from './layout.module.css'
import WhatsApp from "./component/Whatsapp/WhatsApp";

const Layout = () => {
  return (
    <div className={styles.container}>
      <Navbar />
      <Outlet/>
      <Footer/>
      <WhatsApp/>
    </div>
  );
};

export default Layout;
