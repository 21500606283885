import React, { useState } from 'react'
import serviceData from "../../data/services/service.json";
import SingleService from './SingleService';
 
const AllServices = () => {
    const [filteredData, setFilteredData] = useState(serviceData);

  return (
    <>
    
    <div>
      <h1 className="text-xl font-bold text-center text-slate-700">Services</h1>
      {/* GridSection */}
      <div className="mt-5 flex flex-col gap-6 justify-center items-center">
        {/* SingleService */}
       
        {filteredData?.map((service) => {
          return <SingleService service={service} key={service.name} />;
        })}
   
        {/* SingleService */}
      </div>
      {/* GridSection */}
    </div>
    </>
  ) 
}

export default AllServices
