import React from "react";
import styles from "./Rental.module.css";

import headerImg from "../../../media/NavTransformer/overhauling/Header.png";
import card1Img from "../../../media/NavTransformer/overhauling/header img.png";
 
const Rental = () => {
  return (
    <div className={styles.parent}>
      <div className={styles.images}>
        <img src={headerImg} alt="image" />
      </div>

      <div className={styles.child1}>
        <p className={styles.header}>TRANSFORMER FIELD REPAIRS & MAINTENANCE</p>
        <div className={styles.info}>
          <p className={styles.desc}>
            When your electrical needs change or unforeseen circumstances arise,
            having a reliable and temporary electrical solution is invaluable.
            That’s where JP ELECTRICAL AND POWER comes into play. We specialize
            in the Transformer Rental industry, providing flexible and reliable
            solutions to keep your operation running smoothly. Our extensive
            range of rental transformers, covering a range of voltage strengths,
            allows you to scale your electricity supply as needed. From
            temporary projects to unforeseen emergencies, we offer fast
            deployment, on-site installation and 24/7 technical support. With
            well-maintained, industry-compliant transformers, we offer
            cost-effective, trouble-free solutions to ensure you have the power
            you need when you need it. Count on JP ELECTRICAL AND POWER for
            temporary power you can’t compromise.
          </p>
          <div className={styles.image}>
            <img src={card1Img} alt="card img" />
          </div>
        </div>
      </div>

      <div className={styles.child2}>
        <p className={styles.heading}>KEY FEATURES:</p>
        <p className={styles.note}>
        <span>Special Features:</span> Various transformer options: offering a comprehensive range of transformers with different capacity and voltage ratings to suit different needs. Both step-up and step-down transformers should be provided, to ensure flexibility in different power conditions.
        </p>


        <p className={styles.note}>
        <span>Customized Solutions:</span>  Tailor rental packages to match customers’ specific capacity requirements. Assist in the selection of the appropriate transformer type and size for the intended application.
        </p>

        <p className={styles.note}>
        <span>Quick Planning:</span> Ensure that transformers are promptly delivered and installed to minimize downtime. Make it easier to quickly respond to emergencies or emergency electrical needs.
        </p>

        <p className={styles.note}>
        <span>Maintenance and Support:</span>  Perform regular maintenance on rent converters to maintain reliability and performance. Provide ongoing technical support and troubleshooting throughout the lease.
        </p>

        <p className={styles.note}>
        <span>Compliance and Security:</span>  Assure all leased transformers comply with industry standards and safety regulations. Provide documentation and certificates of compliance with transformer quality and safety standards.
        </p>

        <p className={styles.note}>
        <span>Cost-Effective Solution:</span>  Eliminates the need for capital upfront investment in transformers. Enable businesses to develop the capacity they need without the long-term commitment associated with ownership.
        </p>

        <p className={styles.note}>
        <span>Simple Rental Conditions:</span> Offer flexible billing terms, adapting to short-term and long-term electricity needs. Offer options for one-time rentals and recurring contracts as per customer requirements.
        </p>

        <p className={styles.note}>
        <span>Shipping and Logistics:</span>  Follow transportation protocols including delivery of transformers safely and transportation. Ensure efficient transportation to and from the client’s destination.
        </p>
      </div>

      <div className={styles.child3}>
        <p className={styles.heading}>Request:</p>
        <p className={styles.note}>
        <span>Construction Sites:</span>  Electrical appliances, equipment and temporary items.
        </p>


        <p className={styles.note}>
        <span>Events:</span>  Reliable power supply for concerts, exhibitions and outdoor gatherings.
        </p>

        <p className={styles.note}>
        <span>Emergency Backup:</span>  To run during power outages or scheduled maintenance.
        </p>

        <p className={styles.note}>
        <span>Technical Services:</span>   To meet short-term energy requirements at a particular stage of the project.
        </p>


      </div>
    </div>
  );
};

export default Rental;
