import React from 'react'
import styles from './automation2.module.css'
import automation1 from "../../media/automation/automation1/automation1.png";
import autoService1 from "../../media/automation/automation1/automation service.png";
import icon from "../../media/automation/automation1/point.png";
import { NavLink } from "react-router-dom";

const Automation2 = () => {
  return (
    <div className={styles.child2}>
    <div className={styles.child2A}>
      <p className={styles.heading}>
        Field Instrumentation Services, Designing, & Detailing
      </p>
      <img src={automation1} alt="automation src " />

      <p className={styles.desc}>
        Field instrumentation services, design, and detailing are vital for
        optimizing efficiency, precision, and safety in industrial
        operations. Within sectors such as oil and gas, refineries, chemical
        plants, and manufacturing, the reliability of instrumentation
        systems is fundamental. These systems enable real-time monitoring,
        control, and accurate data collection, ensuring seamless operation
        and adherence to stringent safety standards. Installation,
        calibration, and maintenance of instruments like sensors,
        transmitters, gauges, and probes are pivotal tasks. Detailed
        planning and precise design drafting, including P&IDs and control
        panel layouts, are essential for seamless integration. Customized
        solutions tailored to industry needs, compliance with regulations,
        and addressing safety concerns are integral components. Overall,
        these services play a critical role in optimizing industrial
        processes, ensuring smooth operations, and upholding safety and
        quality standards.
      </p>
    </div>

    <div className={styles.child2B}>
      <p className={styles.heading}>Field Instrumentation Services</p>
      <div className={styles.card}>
        <div className={styles.cardChild}>
          <p className={styles.title}>Installation & Commissioning</p>
          <p className={styles.desc}>
            Proper installation and setup of field instruments such as
            sensors, transmitters, actuators, pressure gauges, temperature
            probes, flow meters, and level sensors.
          </p>
        </div>

        <div className={styles.cardChild}>
          <p className={styles.title}>Calibration and Maintenance:</p>
          <p className={styles.desc}>
            Regular calibration to maintain accuracy and functionality of
            instruments, along with routine maintenance to prevent downtime
            and ensure optimal performance.
          </p>
        </div>

        <div className={styles.cardChild}>
          <p className={styles.title}>Troubleshooting and Repairs:</p>
          <p className={styles.desc}>
            Diagnosing and resolving issues with instrumentation systems to
            minimize disruptions to the production process.
          </p>
        </div>
      </div>
    </div>

    <div className={styles.child2C}>
      <p className={styles.heading}>Designing and Detailing</p>
      <div className={styles.card}>
        <div className={styles.cardChild}>
          <p className={styles.title}>Installation & Commissioning</p>
          <p className={styles.desc}>
            Proper installation and setup of field instruments such as
            sensors, transmitters, actuators, pressure gauges, temperature
            probes, flow meters, and level sensors.
          </p>
        </div>

        <div className={styles.cardChild}>
          <p className={styles.title}>Calibration and Maintenance:</p>
          <p className={styles.desc}>
            Regular calibration to maintain accuracy and functionality of
            instruments, along with routine maintenance to prevent downtime
            and ensure optimal performance.
          </p>
        </div>

        <div className={styles.cardChild}>
          <p className={styles.title}>Troubleshooting and Repairs:</p>
          <p className={styles.desc}>
            Diagnosing and resolving issues with instrumentation systems to
            minimize disruptions to the production process.
          </p>
        </div>
      </div>
    </div>

    <div className={styles.child2D}>
      <div className={styles.child2DA}>
        <p className={styles.heading}>
          Instrumentation Services We Provide
        </p>
      </div>

      <div className={styles.child2DB}>
        <div className={styles.card}>
          <div className={styles.cardChild}>
            <img src={autoService1} alt="Auto service 1" />
            <p className={styles.title}>
              Instrument Specification Development
            </p>
            <p className={styles.desc}>
              Collaborative engineering to assist in instrument selection,
              performance criteria setting, and communication protocols for
              seamless integration.
            </p>
          </div>

          <div className={styles.cardChild}>
            <img src={autoService1} alt="Auto service 1" />
            <p className={styles.title}>
              Instrument Specification Development
            </p>
            <p className={styles.desc}>
              Collaborative engineering to assist in instrument selection,
              performance criteria setting, and communication protocols for
              seamless integration.
            </p>
          </div>

          <div className={styles.cardChild}>
            <img src={autoService1} alt="Auto service 1" />
            <p className={styles.title}>
              Instrument Specification Development
            </p>
            <p className={styles.desc}>
              Collaborative engineering to assist in instrument selection,
              performance criteria setting, and communication protocols for
              seamless integration.
            </p>
          </div>

          <div className={styles.cardChild}>
            <img src={autoService1} alt="Auto service 1" />
            <p className={styles.title}>
              Instrument Specification Development
            </p>
            <p className={styles.desc}>
              Collaborative engineering to assist in instrument selection,
              performance criteria setting, and communication protocols for
              seamless integration.
            </p>
          </div>

          <div className={styles.cardChild}>
            <img src={autoService1} alt="Auto service 1" />
            <p className={styles.title}>
              Instrument Specification Development
            </p>
            <p className={styles.desc}>
              Collaborative engineering to assist in instrument selection,
              performance criteria setting, and communication protocols for
              seamless integration.
            </p>
          </div>

          <div className={styles.cardChild}>
            <img src={autoService1} alt="Auto service 1" />
            <p className={styles.title}>
              Instrument Specification Development
            </p>
            <p className={styles.desc}>
              Collaborative engineering to assist in instrument selection,
              performance criteria setting, and communication protocols for
              seamless integration.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.child2E}>
      <p className={styles.heading}>
        Why Choose Us For Field Instrumentation Services, Designing, &
        Detailing
      </p>
      <p className={styles.desc}>
        Choosing a service provider for field instrumentation services,
        designing, and detailing is a crucial decision for industries
        reliant on precise process control. Here are some reasons why
        choosing a provider like us could be advantageous
      </p>
      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>
      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>
      
      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      
      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>

      <div className={styles.whyChoose}>
        <div>
          <img className={styles.whyChild1} src={icon} alt="icon" />
        </div>
        <div className={styles.whyChild2}>
          <p className={styles.heading}>Expertise and Experience</p>
          <p className={styles.desc}>
            We bring extensive expertise and years of experience in field
            instrumentation across diverse industries, ensuring reliable
            solutions tailored to specific needs.
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Automation2