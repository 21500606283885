import React from "react";
import styles from "./industry.module.css";
 const Industry = () => {
  return (
    <>
      <div className={styles.industryParent}>
        <div className={styles.header}>
          <p className={styles.headertitle}>
            The diverse industries we work with.
          </p>
          <p className={styles.headerdesc}>
            At{" "}
            <span className={styles.jptitle}>
              {" "}
              JP ELECTRICAL AND POWER SYSTEM
            </span>
            , we take pride in providing top-notch electrical power solutions to
            a wide range of industries. With our expertise, cutting-edge
            technology, and commitment to excellence, we have been instrumental
            in powering the growth and success of various sectors. Our
            comprehensive range of electrical power systems and services caters
            to the following industries:
          </p>
        </div>

        <div className={styles.leftText1}>
          <div className={`${styles.leftData} ${styles.leftChild}`}>
            <p className={styles.leftTitle}>Oil & Gas</p>
            <p className={styles.leftDesc}>
              Our electrical power solutions are a vital component of the oil
              and gas industry. We specialize in delivering robust, reliable,
              and safety-critical power systems to support exploration,
              drilling, production, and refining operations. Our services
              encompass the design, installation, and maintenance of electrical
              infrastructure, ensuring uninterrupted power supply in often
              challenging and remote environments. With a deep understanding of
              the industry's unique demands, we offer tailored solutions that
              enhance efficiency, reduce downtime, and promote worker safety. We
              are committed to contributing to the success of the oil and gas
              sector by providing cutting-edge electrical power solutions that
              meet its complex and evolving needs.
            </p>
          </div>
          <div className={styles.leftChild}></div>
        </div>

        <div className={styles.rightText1}>
          <div className={styles.rightChild}></div>
          <div className={`${styles.rightData} ${styles.rightChild}`}>
            <p className={styles.rightTitle}>Power Generation</p>
            <p className={styles.rightDesc}>
              In the dynamic realm of power generation, our electrical power
              solutions are a cornerstone of success. We specialize in
              designing, implementing, and maintaining cutting-edge electrical
              infrastructure to meet the high demands of power plants, both
              conventional and renewable. Our expertise covers everything from
              generation to distribution, ensuring the reliability and
              efficiency of power production processes. With a keen focus on
              sustainability, we offer innovative solutions to reduce
              environmental impact and optimize energy production. Our
              commitment to the power generation industry lies in bolstering
              energy output, fostering clean energy initiatives, and securing
              the future of global power supply. We are the trusted partner for
              powering the future of power generation.
            </p>
          </div>
        </div>

        <div className={styles.leftText2}>
          <div className={`${styles.leftData} ${styles.leftChild}`}>
            <p className={styles.leftTitle}>Water Treatment</p>
            <p className={styles.leftDesc}>
              In the water treatment industry, our electrical power solutions
              play a pivotal role in ensuring clean, safe, and sustainable water
              supplies. We specialize in delivering customized electrical
              infrastructure for water treatment plants, optimizing their
              performance and reliability. Our expertise covers the entire
              spectrum, from water intake to distribution, enabling efficient
              processes and environmental sustainability. We are committed to
              enhancing the sector's capacity to provide safe drinking water,
              reduce environmental impact, and promote resource conservation.
              Our innovative solutions drive operational excellence and
              contribute to safeguarding public health. We are dedicated to
              supporting the water treatment industry in its mission to provide
              essential, high-quality water services to communities.
            </p>
          </div>
          <div className={styles.leftChild}></div>
        </div>
        <div className={styles.rightText2}>
          <div className={styles.rightChild}></div>
          <div className={`${styles.rightData} ${styles.rightChild}`}>
            <p className={styles.rightTitle}>Automotive</p>
            <p className={styles.rightDesc}>
              In the fast-paced automotive industry, our electrical power
              solutions are a driving force behind innovation and efficiency. We
              specialize in designing and delivering cutting-edge electrical
              systems for automotive manufacturing, helping streamline
              production processes, enhance vehicle performance, and improve
              sustainability. Our expertise spans from advanced automation
              solutions on the assembly line to electrification initiatives in
              modern vehicles. We're dedicated to powering the future of
              transportation with energy-efficient, reliable, and
              environmentally friendly solutions. By collaborating with industry
              leaders, we ensure that the automotive sector remains at the
              forefront of technology, offering superior vehicles while reducing
              its environmental footprint. We are the power behind the wheels of
              progress in the automotive industry.
            </p>
          </div>
        </div>

        <div className={styles.leftText3}>
          <div className={`${styles.leftData} ${styles.leftChild}`}>
            <p className={styles.leftTitle}>Building Management system (BMS)</p>
            <p className={styles.leftDesc}>
              In the realm of Building Management Systems (BMS), our electrical
              power solutions are instrumental in creating smart, efficient, and
              sustainable building environments. We specialize in providing
              state-of-the-art electrical infrastructure to support BMS,
              optimizing energy management, climate control, and security
              systems. Our expertise spans from designing power distribution
              networks to integrating cutting-edge technology that enhances
              building automation. We are committed to advancing the BMS
              industry by ensuring seamless connectivity, energy conservation,
              and streamlined operations. Our innovative solutions empower
              building managers to create comfortable, safe, and
              energy-efficient spaces while reducing environmental impact. We
              are the trusted partner for powering the future of the Building
              Management System industry.
            </p>
          </div>
          <div className={styles.leftChild}></div>
        </div>
        <div className={styles.rightText3}>
          <div className={styles.rightChild}></div>
          <div className={`${styles.rightData} ${styles.rightChild}`}>
            <p className={styles.rightTitle}>Pharma</p>
            <p className={styles.rightDesc}>
              Our electrical power solutions are a vital component of the oil
              and gas industry. We specialize in delivering robust, reliable,
              and safety-critical power systems to support exploration,
              drilling, production, and refining operations. Our services
              encompass the design, installation, and maintenance of electrical
              infrastructure, ensuring uninterrupted power supply in often
              challenging and remote environments. With a deep understanding of
              the industry's unique demands, we offer tailored solutions that
              enhance efficiency, reduce downtime, and promote worker safety. We
              are committed to contributing to the success of the oil and gas
              sector by providing cutting-edge electrical power solutions that
              meet its complex and evolving needs.
            </p>
          </div>
        </div>

        <div className={styles.leftText4}>
          <div className={`${styles.leftData} ${styles.leftChild}`}>
            <p className={styles.leftTitle}>Brewery</p>
            <p className={styles.leftDesc}>
              In the brewery industry, our electrical power solutions are the
              driving force behind the craft of brewing. We specialize in
              delivering tailored electrical infrastructure for breweries,
              optimizing production processes, quality control, and
              sustainability. Our expertise spans from energy-efficient brewing
              equipment to climate control and automated systems. We are
              committed to supporting breweries in their pursuit of crafting
              exceptional beverages while minimizing environmental impact. Our
              innovative solutions enable brewers to enhance efficiency,
              quality, and productivity. We are the trusted partner for powering
              the brewing industry, ensuring the consistent delivery of
              high-quality brews to enthusiasts around the world. Cheers to
              powering the art of brewing!
            </p>
          </div>
          <div className={styles.leftChild}></div>
        </div>

        <div className={styles.footer}>
          <p className={styles.footerdesc}>
            These are just some of the industries we serve. Our commitment to
            quality, safety, and innovation allows us to adapt and cater to the
            specific needs of each sector. If you don't see your industry listed
            here, please contact us to discuss how we can customize our
            solutions to meet your unique requirements. At{" "}
            <span className={styles.jptitle}>
              {" "}
              JP ELECTRICAL AND POWER SYSTEM
            </span>
            , we are dedicated to powering the future of industries across the
            board. Contact us today to learn more about how our electrical power
            systems can benefit your organization.
          </p>
        </div>
      </div>
    </>
  );
};

export default Industry;
