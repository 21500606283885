import React from "react";
import styles from "./banner.module.css";
const Banner = () => {
  return (
    <>
      <div className={"bg-indigo-950 text-white px-2 py-4 md:py-6 tracking-wide"}>
        <div className={"text-center flex flex-col gap-4"}>
          <p className="text-xl md:text-2xl font-medium">Transformer Life Cycle Management</p>
          <p className="md:text-lg">Zero Breakdown &nbsp; | &nbsp; No Production Loss &nbsp; </p>
        </div>
      </div>
    </>
  );
};

export default Banner;

// login page
