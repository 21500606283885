import React, { useEffect, useState } from "react";
import "./navbar.css"; // Import the CSS file
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../media/jp-logo.png";
import services from "../../data/services/service.json";
//  logo

const Navbar = () => {
  const [hideLogo, setHideLogo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHideLogo(true);
      } else {
        setHideLogo(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeToggle = () => {
    const checkbox = document.getElementById("menubrop");
    if (checkbox.checked) {
      checkbox.checked = false;
    }
  };
  return (
    <header className={`${hideLogo ? "lg:bg-opacity-85 lg:bg-white" : ""}`}>
      <nav className="">
        {/* <div className="logo"> */}
        <div className={`logo  ${hideLogo ? "lg:!hidden" : ""}`}>
          <Link to="/">
            <div>
              <img src={logo} alt="jp-logo" />
              <p>ELECTRICAL AND POWER SYSTEM</p>
              {/* <p>Transformer LifeCycle Management</p> */}
            </div>
          </Link>
        </div>
        <label htmlFor="menubrop" className="bartoggle">
          ≡
        </label>
        <input type="checkbox" id="menubrop" />
        <ul className="NavMenu">
          {hideLogo ? (
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => {
                  return `${
                    isActive
                      ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white"
                      : ""
                  }`;
                }}
                onClick={closeToggle}
              >
                Home
              </NavLink>
            </li>
          ) : (
            ""
          )}
          <li>
            <NavLink
              to="/aboutus"
              className={({ isActive }) => {
                return `${
                  isActive
                    ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white"
                    : ""
                }`;
              }}
              onClick={closeToggle}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                return `${
                  isActive
                    ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white"
                    : ""
                }`;
              }}
              to="/industry"
              onClick={closeToggle}
            >
              Industry
            </NavLink>
          </li>
          <li>
            {/* <NavLink to="javascript:void(0)"
            className={({ isActive }) => {
              return `${
                isActive
                  ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white"
                  : ""
              }`;
            }}
            onClick={closeToggle}
            >
              <label
                onClick={() => navigate(`/services`)}
                htmlFor="serviceDroplist"
                className="toggle"
              >
                Services +
              </label>
            </NavLink> */}
            <div className="flex justify-between">
              <NavLink
                to={"/services"}
                onClick={closeToggle}
                className={({ isActive }) => {
                  return `${
                    isActive
                      ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white w-full"
                      : "w-full"
                  }`;
                }}
              >
                Services <span className="max-sm:hidden">{"+"}</span>
              </NavLink>
              <NavLink
                to={"javascript:void(0)"}
                className={({ isActive }) => {
                  return `${"bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white md:!hidden"}`;
                }}
              >
                <label htmlFor="serviceDroplist" className="toggle">
                  +
                </label>
              </NavLink>
            </div>

            <input type="checkbox" id="serviceDroplist" />

            <ul>
              <li>
                <NavLink to="javascript:void(0)">
                  <label htmlFor="transformerDroplist" className="toggle">
                    Transformer
                  </label>
                </NavLink>
                <input type="checkbox" id="transformerDroplist" />

                <ul>
                  <li>
                    <NavLink
                      onClick={closeToggle}
                      className={({ isActive }) => {
                        return `${
                          isActive
                            ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full !text-white"
                            : ""
                        }`;
                      }}
                      to="/transformer/fieldservice"
                    >
                      {" "}
                      Transformer Field Service{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={closeToggle}
                      className={({ isActive }) => {
                        return `${
                          isActive
                            ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full !text-white"
                            : ""
                        }`;
                      }}
                      to="/transformer/refurbishmentandoverhauling"
                    >
                      Transformer Refurbishment And Overhauling
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={closeToggle}
                      className={({ isActive }) => {
                        return `${
                          isActive
                            ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full !text-white"
                            : ""
                        }`;
                      }}
                      to="/transformer/repaireandmaintainance"
                    >
                      {" "}
                      Transformer Repaire And Maintainance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={closeToggle}
                      className={({ isActive }) => {
                        return `${
                          isActive
                            ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full !text-white"
                            : ""
                        }`;
                      }}
                      to="/transformer/hvlvtesting"
                    >
                      {" "}
                      Transformer HV LV Testing
                    </NavLink>
                  </li>
                </ul>
              </li>
              {services?.map((item, i) => {
                return (
                  <li>
                    <Link to={`javascript:void(0)`} onClick={closeToggle}>
                      {item.categories ? (
                        <label
                          onClick={() =>
                            navigate(`/services/categories/${item.id}`)
                          }
                          htmlFor={`droplist${i}`}
                          className="toggle"
                        >
                          {item.name}
                        </label>
                      ) : (
                        <p
                          onClick={() =>
                            navigate(`/services/details/${item.id}`)
                          }
                        >
                          {item.name}
                        </p>
                      )}
                    </Link>
                    <input type="checkbox" id={`droplist${i}`} />

                    {item.categories ? (
                      <ul>
                        {item.categories?.map((cat) => {
                          return (
                            <>
                              {cat?.map((subcat) => {
                                return (
                                  <li>
                                    <NavLink
                                    className={({ isActive }) => {
                                      return `${
                                        isActive
                                          ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full !text-white"
                                          : ""
                                      }`;
                                    }}
                                    to={`/services/details/${subcat.id}`} onClick={closeToggle}>
                                      {subcat.name}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </li>

          <li>
            <NavLink
              className={({ isActive }) => {
                return `${
                  isActive
                    ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white"
                    : ""
                }`;
              }}
              to="/automation"
              onClick={closeToggle}
            >
              Automation
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => {
                return `${
                  isActive
                    ? "bg-gradient-to-r from-indigo-950 to-indigo-800 h-full text-white"
                    : ""
                }`;
              }}
              to="contact"
              onClick={closeToggle}
            >
              Enquiry
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
