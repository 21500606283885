import React from "react";

import Carousel1 from "./component/carousel/Carousel";
import classes from "./app.module.css";
import Banner from "./component/banner/Banner";
import Service from "./component/sevices/Service";
import Testimonial from "./component/testimonial/Testimonial";
import Contact from "./component/contact/Contact";
import AboutSection from "./component/about-section/AboutSection";
import CustomerStories from "./component/customerStories/CustomerStories";

const App = () => {
  return (
    <>
      <div className={classes.carousel}>
        <div className={classes.navbar}>
          <Carousel1 />
        </div>
        <Banner />
        <Service />
        <AboutSection/>
        <CustomerStories/> 
      </div>
    </>
  );
};

export default App;
