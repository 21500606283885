import React from 'react'
import styles from './WhatWeDo.module.css'
import img1 from "../../media/company/electrical worker team 1.png";
import img2 from "../../media/company/two electrical worker 1.png";

const WhatWeDo = () => {
  return (
    <div className={styles.parentContainer}>
    <div className={styles.child1}>
      <div className={styles.child1A}>
        <div className={styles.child1Ai}>
        



          <div className={styles.title}>
            Who <br /> We <br /> Are?
          </div>
          <div>
            <img src={img1} alt="img 1" />
          </div>
        </div>

        <p>
        <span className={styles.jptitle}> JP ELECTRICAL AND POWER SYSTEM</span>boasts a rich legacy spanning over 15
          years as a leading figure in the transformer asset and life-cycle
          management sector. Our unwavering dedication and commitment to
          delivering top-tier transformers and driving innovation within the
          electricity industry have earned us unparalleled respect, not only
          from our clients but also from our competitors.
        </p>
      </div>

      <div className={styles.child1B}>
        <p className={styles.title}> Our Legacy</p>
        <p className={styles.desc}>
          
          For more than a decade, we've remained steadfast in our pursuit of
          excellence. Our position as a prominent player isn't just a
          testament to our tenure; it mirrors our relentless dedication to
          quality, reliability, and technological advancements.
        </p>
      </div>
      <div className={styles.child1B}>
        <p className={styles.title}> Our Legacy</p>
        <p className={styles.desc}>
          
          For more than a decade, we've remained steadfast in our pursuit of
          excellence. Our position as a prominent player isn't just a
          testament to our tenure; it mirrors our relentless dedication to
          quality, reliability, and technological advancements.
        </p>
      </div>

      <div className={styles.child1B}>
        <p className={styles.title}> Our Legacy</p>
        <p className={styles.desc}>
          
          For more than a decade, we've remained steadfast in our pursuit of
          excellence. Our position as a prominent player isn't just a
          testament to our tenure; it mirrors our relentless dedication to
          quality, reliability, and technological advancements.
        </p>
      </div>

      <div className={styles.child1B}>
        <p className={styles.title}> Our Legacy</p>
        <p className={styles.desc}>
          
          For more than a decade, we've remained steadfast in our pursuit of
          excellence. Our position as a prominent player isn't just a
          testament to our tenure; it mirrors our relentless dedication to
          quality, reliability, and technological advancements.
        </p>
      </div>
    </div>
    <div className={styles.child2}>
      <img
        src={img2}
        alt="img 2
          "
      />
    </div>
  </div>
  )
}

export default WhatWeDo