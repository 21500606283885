import React from "react";
import styles from "./FieldService.module.css";
import headerimg from "../../../media/NavTransformer/FiledService/field services.jpg";
import card1img from "../../../media/NavTransformer/FiledService/card1img.png";
import card2img from "../../../media/NavTransformer/FiledService/card2img.png";
import card3img from "../../../media/NavTransformer/FiledService/card3img.png";

import max1img from "../../../media/NavTransformer/FiledService/productivity.png";
import max2img from "../../../media/NavTransformer/FiledService/efficiency.png";
import max3img from "../../../media/NavTransformer/FiledService/aassurance.png";
import { keyFeatures, maxFreeBenefits } from "../../../../constants";

// const FieldService = () => {
//   return (
//     <div className={styles.FieldServiceParent}>
//       <div className={styles.image}>
//         <img src={headerimg} alt="img" />
//       </div>

//       <div className={styles.child}>
//         <p className={styles.title}>Field Services</p>
//         <p className={styles.desc}>
//           At{" "}
//           <span className={styles.jptitle}>JP ELECTRICAL AND POWER SYSTEM</span> &nbsp;
//           , we take great pride in our ability to deliver high quality field
//           services specifically tailored to meet the needs of our customers. Our
//           team consists of highly experienced engineers equipped with the latest
//           technology. They offer a wide variety of on-site services tailored to
//           meet your unique needs. Whether it’s accurate installation and
//           commissioning, routine maintenance, diagnostic inspections, repairs,
//           or overhauls, our comprehensive field services ensure that your
//           transformers works flawlessly and efficiently. We also offer
//           specialized services, such as oil extraction and cleaning that go
//           beyond the standard offerings. In addition to our commitment to safety
//           and reliability, we are dedicated to providing solutions tailored to
//           the unique needs of each of our customers. Our outreach is a testament
//           to our unwavering commitment to providing high-quality, customized
//           services.
//         </p>
//       </div>

//       <div className={styles.child}>
//         <p className={styles.title}>MaxFree Analysis</p>
//         <p className={styles.desc}>
//           At
//           <span className={styles.jptitle}>JP ELECTRICAL AND POWER SYSTEM </span> &nbsp;
//           we are proud to offer MaxFree Analysis, a new service dedicated to
//           improving transformer performance and efficiency. Our exclusive
//           MaxFree inspection uses leading-edge diagnostic methods to optimize
//           and enhance your transformer’s performance. This proprietary
//           application is a transformational tool, using advanced diagnostic
//           techniques to analyze complex transformation processes. Through
//           comprehensive analysis, we uncover potential areas for improvement and
//           offer customized strategies to improve efficiency and increase
//           efficiency. MaxFree Analysis stands as proof of our commitment to
//           pushing the boundaries of transformer innovation, ensuring your system
//           operates at its maximum potential. Experience the variable power of
//           MaxFree Analysis with{" "}
//           <span className={styles.jptitle}>JP ELECTRICAL AND POWER SYSTEM </span>
//           .
//         </p>
//       </div>

//       <div className={styles.child}>
//         <p className={styles.title11}>What is Maxfree Analysis?</p>
//         <p className={styles.desc}>
//           MaxFree Analysis is an advanced analysis service offered by <span className={styles.jptitle}>JP ELECTRICAL AND POWER SYSTEM </span>. It is designed to check the efficiency
//           and effectiveness of the transformer. This study uses state-of-the-art
//           diagnostic tools and techniques, such as dissolved gas analysis (DGA),
//           thermography, partial discharge measurements, and other advanced
//           techniques The primary objective of the MaxFree Analysis is to
//           identify areas where transformer performance can be improved. Through
//           in-depth diagnostics and performance analysis, this application
//           provides a comprehensive analysis of transformer status. It tests a
//           variety of factors affecting performance, load capacity, insulation
//           status and overall reliability. Based on the findings of the
//           inspection,{" "}
//           <span className={styles.jptitle}>JP ELECTRICAL AND POWER SYSTEM</span> &nbsp;
//           offers recommendations and customized solutions to improve transformer
//           performance. These recommendations aim to increase efficiency, improve
//           reliability, reduce operating costs, and extend the life of
//           transformers. Overall, the MaxFree Analysis service is dedicated to
//           helping customers maximize the capacity of their transformers by
//           identifying inefficiencies, addressing potential issues, and
//           developing processes tailored to their performance and lifecycle
//           length to be provided
//         </p>
//       </div>

//       <div className={styles.child}>
//         <p className={styles.title11}>Key Feautres</p>
//         <div className={styles.cards}>
//           <div className={styles.card}>
//             <div className={styles.cardImg}>
//               <img src={card1img} alt="card img 1" />
//             </div>
//             <div className={styles.cardData}>
//               <p className={styles.cardHeading}>In-Depth Diagnostic Testing</p>
//               <p className={styles.cardDesc}>
//                 We scrutinize transformers and use advanced diagnostic tests
//                 such as DGA, thermography and partial discharge measurements to
//                 assess their operational health.
//               </p>
//             </div>
//           </div>

//           <div className={styles.card}>
//             <div className={styles.cardImg}>
//               <img src={card2img} alt="card img 1" />
//             </div>
//             <div className={styles.cardData}>
//               <p className={styles.cardHeading}>Performance Research</p>
//               <p className={styles.cardDesc}>
//                 Our evaluation examines various aspects of performance such as
//                 efficiency, load capacity, insulation condition and overall
//                 reliability.
//               </p>
//             </div>
//           </div>

//           <div className={styles.card}>
//             <div className={styles.cardImg}>
//               <img src={card3img} alt="card img 1" />
//             </div>
//             <div className={styles.cardData}>
//               <p className={styles.cardHeading}>Customized Suggestions</p>
//               <p className={styles.cardDesc}>
//                 Based on our findings, we provide recommendations designed to
//                 improve transformer efficiency, increase efficiency, and extend
//                 service life.
//               </p>
//             </div>
//           </div>

//         </div>
//       </div>

//       <div className={styles.child}>
//         <p className={styles.title11}>Benefits of MaxFree Analysis</p>
//         <div className={styles.cardsForMax}>
//           <div className={styles.cardForMax}>
//             <div className={styles.cardImg}>
//               <img src={max1img} alt="card img 1" />
//             </div>

//             <p className={styles.cardHeading}>Enhanced Productivity</p>
//             <p className={styles.cardDesc}>
//               Identify areas of inefficiency and areas that need to be improved
//               to improve transformer efficiency.
//             </p>
//           </div>

//           <div className={styles.cardForMax}>
//             <div className={styles.cardImg}>
//               <img src={max2img} alt="card img 1" />
//             </div>

//             <p className={styles.cardHeading}>Cost Efficiency</p>
//             <p className={styles.cardDesc}>
//               Optimize transformer efficiency to reduce operating costs and
//               avoid premature replacement.
//             </p>
//           </div>

//           <div className={styles.cardForMax}>
//             <div className={styles.cardImg}>
//               <img src={max3img} alt="card img 1" />
//             </div>

//             <p className={styles.cardHeading}>Reliability Assurance</p>
//             <p className={styles.cardDesc}>
//               Ensure reliability and security by addressing potential issues
//               before they escalate.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FieldService;

const FieldService = () => {
  return (
    <>
      <div>
        <img className="w-full" src={'/feild-service/banner.jpg'}></img>
      </div>
      <div className="flex justify-center px-4 md:px-10">
        <div className="max-w-[1280px]">
          {/* section1 */}
          <div className="mt-10 flex flex-col gap-5">
            <p className="text-4xl font-bold text-red-500">Field Services</p>
            <p className="leading-6">
              At{" "}
              <span className="text-black font-semibold ">
                JP ELECTRICAL AND POWER SYSTEM
              </span>
              , we take great pride in our ability to deliver high quality field
              services specifically tailored to meet the needs of our customers.
              Our team consists of highly experienced engineers equipped with
              the latest technology. They offer a wide variety of on-site
              services tailored to meet your unique needs. Whether it’s accurate
              installation and commissioning, routine maintenance, diagnostic
              inspections, repairs, or overhauls, our comprehensive field
              services ensure that your transformers works flawlessly and
              efficiently. We also offer specialized services, such as oil
              extraction and cleaning that go beyond the standard offerings. In
              addition to our commitment to safety and reliability, we are
              dedicated to providing solutions tailored to the unique needs of
              each of our customers. Our outreach is a testament to our
              unwavering commitment to providing high-quality, customized
              services.
            </p>
          </div>
          {/* section1 */}

          {/* section2 */}
          <div className="mt-10 flex flex-col gap-5">
            <p className="text-4xl font-bold text-red-500">MaxFree Analysis</p>
            <p className="leading-6">
              At{" "}
              <span className="text-black font-semibold ">
                JP ELECTRICAL AND POWER SYSTEM
              </span>
              , we are proud to offer MaxFree Analysis, a new service dedicated
              to improving transformer performance and efficiency. Our exclusive
              MaxFree inspection uses leading-edge diagnostic methods to
              optimize and enhance your transformer’s performance. This
              proprietary application is a transformational tool, using advanced
              diagnostic techniques to analyze complex transformation processes.
              Through comprehensive analysis, we uncover potential areas for
              improvement and offer customized strategies to improve efficiency
              and increase efficiency. MaxFree Analysis stands as proof of our
              commitment to pushing the boundaries of transformer innovation,
              ensuring your system operates at its maximum potential. Experience
              the variable power of MaxFree Analysis with JP ELECTRICAL AND
              POWER SYSTEM.
            </p>
          </div>
          {/* section2 */}

          {/* section3 */}
          <div className="mt-10 flex flex-col gap-5">
            <p className="text-2xl font-bold ">What is MaxFree Analysis?</p>
            <p className="leading-6">
              MaxFree Analysis is an advanced analysis service offered by{" "}
              <span className="text-black font-semibold ">
                JP ELECTRICAL AND POWER SYSTEM
              </span>{" "}
              . It is designed to check the efficiency and effectiveness of the
              transformer. This study uses state-of-the-art diagnostic tools and
              techniques, such as dissolved gas analysis (DGA), thermography,
              partial discharge measurements, and other advanced techniques The
              primary objective of the MaxFree Analysis is to identify areas
              where transformer performance can be improved. Through in-depth
              diagnostics and performance analysis, this application provides a
              comprehensive analysis of transformer status. It tests a variety
              of factors affecting performance, load capacity, insulation status
              and overall reliability. Based on the findings of the inspection,
              JP ELECTRICAL AND POWER SYSTEM offers recommendations and
              customized solutions to improve transformer performance. These
              recommendations aim to increase efficiency, improve reliability,
              reduce operating costs, and extend the life of transformers.
              Overall, the MaxFree Analysis service is dedicated to helping
              customers maximize the capacity of their transformers by
              identifying inefficiencies, addressing potential issues, and
              developing processes tailored to their performance and lifecycle
              length to be provided
            </p>
          </div>
          {/* section3 */}

          {/* section4 */}
          <div className="mt-10 flex flex-col gap-8">
            <p className="text-2xl text-center font-bold">Key Features</p>
            {/* girds */}
            <div className="flex flex-wrap justify-around gap-4">
              {/* grid1 */}
              {keyFeatures.map((e) => {
                return (
                  <div class="max-w-sm rounded-md overflow-hidden shadow-lg bg-white py-2">
                    <div className="flex justify-center items-center">
                      <img
                        className="w-20"
                        src={e.img}
                        alt="Sunset in the mountains"
                      />
                    </div>
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">{e.title}</div>
                      <p class="text-gray-700 text-base">{e.desc}</p>
                    </div>
                  </div>
                );
              })}

              {/* grid1 */}
            </div>
            {/* girds */}
          </div>
          {/* section4 */}
          {/* section5 */}
          <div className="mt-10 flex flex-col gap-8">
            <p className="text-2xl text-center font-bold">
              Benefits of MaxFree Analysis
            </p>
            {/* girds */}
            <div className="flex flex-wrap justify-around gap-4">
              {/* grid1 */}
              {maxFreeBenefits.map((e) => {
                return (
                  <div class="max-w-sm rounded-md overflow-hidden shadow-lg bg-white py-2">
                    <div className="flex justify-center items-center">
                      <img
                        className="w-20"
                        src={e.img}
                        alt="Sunset in the mountains"
                      />
                    </div>
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">{e.title}</div>
                      <p class="text-gray-700 text-base">{e.desc}</p>
                    </div>
                  </div>
                );
              })}

              {/* grid1 */}
            </div>
            {/* girds */}
          </div>
          {/* section5 */}
        </div>
      </div>
    </>
  );
};

export default FieldService;
