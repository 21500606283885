import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { reviews } from "../../constants";

const CustomerStories = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const colonImg = "/home/colon.png";
  const feedBack = reviews.map((e) => {
    return (
      <div
        key={e.name}
        className="shadow-md max-w-[380px] mx-auto my-4 px-4 py-4 font-sans flex flex-col gap-2 mb-12 rounded-md"
      >
        <img src={colonImg} className="w-5" alt="" />

        <p className="text-slate-700 tracking-wide leading-6">{e.feedBack}</p>
        <h1 className="text-xl font-semibold">{e.name}</h1>

        <h1 className="font-medium">{e.work}</h1>
      </div>
    );
  });
  return (
    <div className="justify-center flex">
      <div className="mt-10 w-full  md:px-10 max-w-[1280px] px-4 lg:px-0">
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-medium text-center">
            What Client Say
          </h1>
          <div className="">
            <Carousel showDots={true} responsive={responsive}>
              {/* review */}
             {feedBack}
              {/* review */}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerStories;
