import React from "react";
import styles from "./footer.module.css";
import footerlogo from "../media/JP logo 1.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-5">
      <div className="bg-slate-200 flex justify-center">
        <div
          className={
            "flex py-12 w-full justify-between  md:px-10 px-4 lg:px-0 gap-6 max-w-[1280px] flex-wrap flex-col md:flex-row"
          }
        >
          <div className={" flex flex-col gap-4"}>
            <p className={``}>
              <img src={footerlogo} alt=" footer img" />
            </p>

            <div className="flex flex-col gap-3">
              <p className={""}>Follow us on:</p>
              <div className={"flex gap-3"}>
                <Link>
                  <img
                    src={require("../media/icon/entypo-social_facebook.png")}
                    alt=" footer img"
                  />
                </Link>

                <Link>
                  <img
                    src={require("../media/icon/iconoir_youtube.png")}
                    alt=" footer img"
                  />
                </Link>
                <Link>
                  <img
                    src={require("../media/icon/logos_twitter.png")}
                    alt=" footer img"
                  />
                </Link>
                <Link>
                  <img
                    src={require("../media/icon/mdi_instagram.png")}
                    alt=" footer img"
                    className="w-5"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className={" flex  flex-col gap-4"}>
            <p className={"font-semibold md:text-xl"}>QUICK LINKS</p>
            <Link to={"/"}> Home </Link>
            <Link to={"/services"}> Services </Link>
            <Link to={"/industry"}> Industry</Link>
            <Link to={"/automation"}> Automation</Link>
          </div>

          <div className={"flex flex-col gap-4"}>
            <p className={"font-semibold md:text-xl"}>Address </p>
            <Link
              to="https://maps.google.com?q=1600 Amphitheatre Parkway, Mountain View, CA"
              className=" max-w-[200px] leading-6"
              target="_blank"
            >
              {process.env.REACT_APP_JP_ADDRESS}
            </Link>
          </div>

          <div className={" flex flex-col  gap-4"}>
            <p className={"font-semibold md:text-xl"}>Key Personnel</p>
            <Link to={"tel:9990764523"}>
              +91 {process.env.REACT_APP_JP_MOBILE_NUMBER}
            </Link>
            <Link to={"tel:7303561471"}>
              +91 {process.env.REACT_APP_JP_MOBILE_NUMBER2}
            </Link>
            <Link to={`mailto:${process.env.REACT_APP_JP_WEBSITE}`}>
              {process.env.REACT_APP_JP_WEBSITE}
            </Link>
          </div>
        </div>
      </div>
      <div className={"bg-indigo-950 text-white py-4 text-center"}>
        <p className="text-sm">
          ©JP Electricals. All Rights Reserved. Designed & Developed By{" "}
          <Link
            to={"https://www.deinertech.com/"}
            target="_blank"
            className="hover:underline"
          >
            Deinertech Software Pvt. Ltd.
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
