import React from "react";
import styles from "./WhyChooseUs.module.css";
import img1 from "../../media/company/electrical worker team 1.png";
import img2 from "../../media/company/two electrical worker 1.png";
import icon1 from "../../media/company/icon-man.jpg";

const WhyChooseUs = () => {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.child1}>
        <div className={styles.child1A}>
          <div className={styles.child1Ai}>
            <div className={styles.title}>
              Who <br /> We <br /> Are?
            </div>
            <div>
              <img src={img1} alt="img 1" />
            </div>
          </div>

          <p>
            JP ELECTRICAL AND POWER SYSTEM boasts a rich legacy spanning over 15
            years as a leading figure in the transformer asset and life-cycle
            management sector. Our unwavering dedication and commitment to
            delivering top-tier transformers and driving innovation within the
            electricity industry have earned us unparalleled respect, not only
            from our clients but also from our competitors.
          </p>
        </div>

        <div className={styles.child1B}>
          <div className={styles.card}>
            <div className={styles.images}>
              <img src={icon1} alt="card img" />
            </div>

            <div className={styles.Carddesc}>
              <p className={styles.title}>Expertise and Experience</p>
              <p className={styles.desc}>
                {" "}
                With over 15 years in the industry, we bring unparalleled
                expertise and a rich legacy of delivering exceptional
                transformer solutions and services.
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.images}>
              <img src={icon1} alt="card img" />
            </div>

            <div className={styles.Carddesc}>
              <p className={styles.title}>Expertise and Experience</p>
              <p className={styles.desc}>
                {" "}
                With over 15 years in the industry, we bring unparalleled
                expertise and a rich legacy of delivering exceptional
                transformer solutions and services.
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.images}>
              <img src={icon1} alt="card img" />
            </div>

            <div className={styles.Carddesc}>
              <p className={styles.title}>Expertise and Experience</p>
              <p className={styles.desc}>
                
                With over 15 years in the industry, we bring unparalleled
                expertise and a rich legacy of delivering exceptional
                transformer solutions and services.
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.images}>
              <img src={icon1} alt="card img" />
            </div>

            <div className={styles.Carddesc}>
              <p className={styles.title}>Expertise and Experience</p>
              <p className={styles.desc}>
                
                With over 15 years in the industry, we bring unparalleled
                expertise and a rich legacy of delivering exceptional
                transformer solutions and services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.child2}>
        <img
          src={img2}
          alt="img 2
          "
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;
