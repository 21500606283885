import React, { useEffect } from "react";
import SimilarProduct from "../../component/similarProducts/SimilarProduct";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useParams } from "react-router-dom";
import products from "../../data/products/products.json";
import serviceData from "../../data/services/serviceDetails.json";
import Enquire from "../../component/enquiry/Enquire";
const ProductDetails = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const { id } = useParams();
  const prodDetails = products.filter((prod) => prod.id === id);

  const currentService = serviceData[prodDetails[0]?.serviceId];
  let similarProducts = [];
  if (currentService) {
    similarProducts = currentService.categories?.filter((cat) => cat.id !== id);
  }
  const product = similarProducts.map((cat) => <SimilarProduct cat={cat} />);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <div className="">
      <div class="py-8">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-sm py-6">
          <div class="flex flex-col md:flex-row -mx-4">
            <div class="md:flex-1 flex justify-center px-4 border">
              <div class="rounded-lg w-[360px] h-[360px]">
                <img
                  class="w-full h-full object-contain"
                  src={`${prodDetails[0].img}`}
                  alt="Product Image"
                />
              </div>
            </div>
            <div class="md:flex-1 px-4 flex flex-col gap-8 ">
              <p class="text-gray-500 text-xs max-sm mt-2">
                <Link to={"/"}>Home</Link>/{" "}
                <Link to={"/services"}>Services</Link>
                {prodDetails[0]?.serviceName ? (
                  <Link to={`/services/categories/${prodDetails[0].serviceId}`}>
                    {`/ ` + prodDetails[0].serviceName}
                  </Link>
                ) : (
                  ""
                )}
                {`/ ` + prodDetails[0].name}
              </p>
              <h2 class="text-2xl font-bold text-gray-800 ">
                {prodDetails[0]?.name}
              </h2>

              {prodDetails[0]?.price ? (
                <div class="flex ">
                  <div class="mr-4">
                    <span class="font-bold text-gray-700 text-lg ">
                      Price:{" "}
                    </span>
                    <span class="text-gray-600 ">{prodDetails[0]?.price}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* quote section */}
              <div>
                {/* <button className="border border-blue-700 rounded-md px-4 py-1 text-sm">
                  Get a Price/Quote
                </button> */}
              </div>
              {/* quote section */}
              {/* enquiryButtons */}
              <div className="flex flex-col gap-2 text-white md:text-lg">
                {/* <button className="bg-blue-800 px-6 py-2 rounded-md">
                  REQUEST TO CALL BACK
                </button> */}
                <Enquire className={"bg-indigo-900 px-6 py-2 rounded-md w-full uppercase"} productName={prodDetails[0]?.name}/>
              </div>
              {/* enquiryButtons */}
            </div>
          </div>
        </div>
      </div>

      <div class="  py-8">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-sm py-6">
          {/* first section Repairing Services Price And Quantity*/}
          {prodDetails[0]?.price || prodDetails[0]?.quantity ? (
            <>
              <div className="md:text-lg border-b-2">
                <h2 className="mb-1 font-semibold">
                  {prodDetails[0]?.name} Price And Quantity
                </h2>
              </div>

              <div className="flex justify-between gap-4 flex-col md:flex-row ">
                {prodDetails[0]?.quantity ? (
                  <div className="md:w-[50%] flex p-5 max-sm:px-0 justify-between">
                    <p className=" text-slate-600 w-[50%]">
                      Minimum Order Quantity:{" "}
                    </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0].quantity}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {prodDetails[0]?.price ? (
                  <div className="md:w-[50%]  p-5 max-sm:px-0 justify-between flex">
                    <p className="w-[50%] text-slate-600">Price: </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.price}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
          {/* first section Repairing Services Price And Quantity */}
          {/* second section Trade Information*/}
          {prodDetails[0]?.tradeInfo ? (
            <>
              <div className="md:text-lg text-sm border-b-2">
                <h2 className="mb-1 font-semibold">
                  {prodDetails[0]?.name} Trade Information
                </h2>
              </div>
              {/* details */}
              <div className="flex justify-between gap-4 flex-col md:flex-row ">
                {prodDetails[0]?.tradeInfo.payementTerms ? (
                  <div className="md:w-[50%] flex p-5 max-sm:px-0 justify-between ">
                    <p className=" text-slate-600 w-[50%]">Payment Terms: </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo?.payementTerms}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {prodDetails[0]?.tradeInfo?.supplyAbility ? (
                  <div className="md:w-[50%]  p-5 max-sm:px-0 justify-between flex">
                    <p className=" text-slate-600 w-[50%]">Supply Ability: </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo?.supplyAbility}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* details */}
              {/* details */}
              <div className="flex justify-between gap-4 flex-col md:flex-row ">
                {prodDetails[0]?.tradeInfo.sampleAvailable ? (
                  <div className="md:w-[50%] flex p-5 max-sm:px-0 justify-between ">
                    <p className=" text-slate-600 w-[50%]">
                      Sample Available:{" "}
                    </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo.sampleAvailable}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {prodDetails[0]?.tradeInfo.samplePolicy ? (
                  <div className="md:w-[50%]  p-5 max-sm:px-0 justify-between flex">
                    <p className=" text-slate-600 w-[50%]">Sample Policy: </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo.samplePolicy}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* details */}
              {/* details */}
              <div className="flex justify-between gap-4 flex-col md:flex-row ">
                {prodDetails[0]?.tradeInfo.packagingDetails ? (
                  <div className="md:w-[50%] flex p-5 max-sm:px-0 justify-between ">
                    <p className=" text-slate-600 w-[50%]">
                      Packaging Details:{" "}
                    </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo.packagingDetails}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {prodDetails[0]?.tradeInfo.mainDomesticMarket ? (
                  <div className="md:w-[50%]  p-5 max-sm:px-0 justify-between flex">
                    <p className=" text-slate-600 w-[50%]">
                      Main Domestic Market:{" "}
                    </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo.mainDomesticMarket}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* details */}
              <div className="flex justify-between gap-4 flex-col md:flex-row ">
                {prodDetails[0]?.tradeInfo.mainExportMarket ? (
                  <div className="md:w-[50%] flex p-5 max-sm:px-0 justify-between ">
                    <p className=" text-slate-600 w-[50%]">
                      Main Export Market(s):{" "}
                    </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo?.mainExportMarket}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {prodDetails[0]?.tradeInfo?.deliveryTime ? (
                  <div className="md:w-[50%]  p-5 max-sm:px-0 justify-between flex">
                    <p className=" text-slate-600 w-[50%]">Delivery Time: </p>
                    <p className="w-[50%] text-right md:text-left">
                      {prodDetails[0]?.tradeInfo?.deliveryTime}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* details */}
            </>
          ) : (
            ""
          )}
          {/* details */}

          {/* Second section Trade Information */}
          {/* third section description*/}
          {prodDetails[0].description ? (
            <>
              <div className="md:text-lg border-b-2">
                <h2 className="mb-1 font-semibold">Product Description</h2>
              </div>
              {/* details */}
              <div className="p-5 max-sm:px-0 text-sm leading-6">
                {prodDetails[0].description}
              </div>
              {/* details */}
            </>
          ) : (
            ""
          )}
          {/* third section description */}
          {/* 4th section specification */}
          {prodDetails[0]?.specification ? (
            <>
              <div className="md:text-lg">
                <h2 className="mb-1 font-semibold">SPECIFICATION</h2>
              </div>
              {/* details */}
              <div className="flex justify-center">
                {/* table */}
                <div className=" bg-white w-full border border-black border-b-0 border-collapse max-w-2xl">
                  {/* map */}
                  {prodDetails[0].specification.map((spec) => {
                    return (
                      <div className="flex text-sm">
                        <span className="w-[50%] p-2 border-r border-b border-black">
                          {Object.keys(spec)[0]}
                        </span>
                        <span className="w-[50%] p-2 border-b border-black">
                          {Object.values(spec)[0]}
                        </span>
                      </div>
                    );
                  })}
     
                  {/* map */}
                </div>
                {/* table */}
                <div></div>
              </div>
              {/* details */}
            </>
          ) : (
            ""
          )}
          {/* 4th section specification */}
          {/* 5th section specification */}
          {prodDetails[0]?.workDuration ? (
            <>
              <div className="md:text-lg">
                <h2 className="mb-1 font-semibold">Work Duration</h2>
              </div>
              {/* details */}
              <div className="">
                <ul className="list-disc list-inside">
                  {prodDetails[0]?.workDuration?.map((wrk) => {
                    return <li>{wrk}</li>;
                  })}
                </ul>
              </div>
              {/* details */}
            </>
          ) : (
            ""
          )}
          {/* 5th section specification */}
        </div>
      </div>
      {currentService ? (
        <div class="py-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-sm py-6">
            <div>
              <h1 className="text-2xl text-slate-600 font-semibold text-center">
                {`Similar Products in "${prodDetails[0].serviceName}" Category`}
              </h1>
            </div>
            <div className="mt-10">
              <Carousel
                className="flex md:justify-center"
                showDots={true}
                responsive={responsive}
              >
                {product}
              </Carousel>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductDetails;
