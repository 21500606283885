import React from "react";
import { useNavigate } from "react-router-dom";

const AboutSection = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center">
      <div className="mt-10 max-w-[1280px] md:px-10 px-4 lg:px-0">
        <div className="flex flex-col lg:flex-row justify-between gap-10 lg:gap-0">
          {/* about image */}
          {/* <div className="w-full lg:w-[48%] hidden lg:block">
          <img
            src={'/home/aboutSection.jpg'}
            alt=""
            className="w-full"
          />
        </div> */}
          {/* about image */}

          {/* about description */}
          <div className="flex-col flex gap-5 w-full lg:w-[48%] ">
            <div className="flex flex-col gap-5">
              <h3 className="uppercase tracking-widest text-indigo-900  ">
                About us
              </h3>
              <h1 className="text-2xl md:text-3xl lg:text-4xl  tracking-wide">
                Welcome To JP Electricals
              </h1>
            </div>
            <div className="flex flex-col gap-4 text-slate-700 tracking-wide leading-7">
              <p>
              JP Electrical and Power System boasts a rich legacy spanning over a decade, solidifying our position as a prominent player in the transformer asset and lifecycle management industry. Renowned for our unwavering dedication and commitment to excellence, we've cultivated a reputation that garners the trust and respect of clients and competitors alike. Our approach transcends the ordinary, serving as trusted advocates for our customers while consistently delivering outstanding results.
              </p>
              <p>
              Our extensive expertise encompasses a wide spectrum of electrical and power systems, allowing us to offer tailored solutions that precisely align with the diverse needs of our clientele. With a team of highly skilled professionals at our core, we remain at the forefront of innovation, continuously integrating the latest advancements to deliver cutting-edge solutions. This commitment ensures not only the optimization of efficiency and reliability but also the paramount importance of safety in all our endeavors.
              </p>
            </div>
            <button
              onClick={() => navigate("/aboutus")}
              className="bg-indigo-900 hover:bg-indigo-700 px-4 py-2 text-white rounded-md "
            >
              Know More
            </button>
            {/* <div>
            <button className="bg-pink-700 px-4 py-2">Read More</button>
          </div> */}
          </div>
          {/* about description  */}
          {/* about image */}
          <div className="w-full lg:w-[48%] hidden lg:block">
            <img
              src={"/home/aboutAutomation.jpg"}
              alt=""
              className="w-full rounded-lg"
            />
          </div>
          {/* about image */}
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
