import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import {useNavigate} from 'react-router-dom'
const Services = () => {
  const [value, setValue]=useState('')
  const navigate=useNavigate()
 
  const handleSearch=(e)=>{ 
    e.preventDefault()
    if(!value.trim())return
    navigate(`/services/search/${value}`)
}

  return (
    <div className="w-full mt-12 px-4">
      <div className="items-center flex justify-center">
        <form onSubmit={handleSearch} className=" rounded-full text-sm text-gray-900 border border-gray-300  bg-gray-50 hover:ring-indigo-700 hover:border-indigo-800 w-full flex max-w-3xl ps-10 p-2">
          <input
          required
            type="search"
            placeholder="Search Product/Services..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="outline-none bg-transparent focus:ring-0 w-full placeholder:text-slate-500 "
          />
          <button
            
            type="submit"
            className="text-white  end-2.5 bottom-2.5 bg-indigo-900 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-full text-sm px-5 py-2 dark:bg-indigo-900 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
          >
            Search
          </button>
        </form>
      </div>
      <div className=" my-5  flex justify-center w-full">
        <hr className="max-w-6xl w-full" />
      </div>
      
      <Outlet/>
      
    </div>
  );
};

export default Services;


