import React from 'react'
import { Link } from 'react-router-dom'
import wpLogo from '../../component/media/whatsapp/whatsapp.png'
const WhatsApp = () => {
  return (
    <div>
       <div class="p-3 fixed bottom-0 right-0">
      <Link to="https://wa.me/+919990764523" target="_blank">
        <img src={wpLogo} alt="" width={60} />
      </Link>
     </div>
    </div> 
  )
}

export default WhatsApp
