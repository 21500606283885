import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Industry from "./component/NavIndustry/Industry";

import {
  RouterProvider,
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  Outlet,
} from "react-router-dom";
import Layout from "./Layout";
import Contact from "./component/contact/Contact";
import Transformer1 from "./component/NavService/TransformerService1/Transformer1";
import Automation1 from "./component/NavAutomation/automation1/Automation1";
import Automation2 from "./component/NavAutomation/automation2/Automation";
import Safety from "./component/NavCompany/safety/Safety";
import WhoWeAre from "./component/NavCompany/WhoWeAre/WhoWeAre";
import WhatWeDo from "./component/NavCompany/WhatWeDo/WhatWeDo";
import WhyChooseUs from "./component/NavCompany/WhyChooseUs/WhyChooseUs";
import WhatClientSay from "./component/NavCompany/WhatClientSay/WhatClientSay";
import TransformerService2 from "./component/NavService/TransformerService2/TransformerService2";
import TransformerService$ from "./component/NavService/TransformerService4/TransformerService4";
import TransformerService3 from "./component/NavService/TransformerService3/TransformerService3";
import TransformerService4 from "./component/NavService/TransformerService4/TransformerService4";
import TransformerService5 from "./component/NavService/Transformerservice5/TransformerService5";
import Aboutus from "./component/NavAboutus/Aboutus";
import NavTransformer from "./component/NavService/navTransformer/NavTransformer";
import FieldService from "./component/NavService/navTransformer/Transformer1/FieldService";
import RefurbushmentAndOverhouling from "./component/NavService/navTransformer/Transformer2/RefurbushmentAndOverhouling";
import RepairAndMaintanace from "./component/NavService/navTransformer/Transformer3/RepairAndMaintanace";
import HVLvTesting from "./component/NavService/navTransformer/Transformer4/HVLvTesting";
import Rental from "./component/NavService/navTransformer/Transformer5/Rental";
import Automation from "./component/NavAutomation/Automation/Automation";
import Maintainance from "./component/NavMaintainance/Maintainance";
import ServicePage from "./pages/services/Services";
import AllServices from "./pages/services/AllServices";
import Categories from "./pages/categories/Categories";
import Products from "./pages/products/Products";
import ProductDetails from "./pages/products/ProductDetails";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from 'react-helmet'
const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>

      <Route path="/" element={<Layout />}>

        <Route index path="" element={<App />} />
        <Route path="/services" element={<ServicePage />}>
        <Route path="" element={<AllServices />} />
        <Route path="categories/:id" element={<Categories />} />
        <Route path="search/:keyWord" element={<Products/>} />
        <Route path="details/:id" element={<ProductDetails/>}/>
      </Route>
        <Route index path="/aboutus" element={<Aboutus />} />
        <Route path="company">
          <Route index path="safety" element={<Safety />} />
          <Route path="whoweare" element={<WhoWeAre />} />
          <Route path="whatwedo" element={<WhatWeDo />} />
          <Route path="whychooseus" element={<WhyChooseUs />} />
          <Route path="whatclientsay" element={<WhatClientSay />} />
        </Route>
        <Route path="industry" element={<Industry />} />
        <Route path="transformer">
          <Route path="fieldservice" element={<FieldService />} />
          <Route
            path="refurbishmentandoverhauling"
            element={<RefurbushmentAndOverhouling />}
          />
          <Route
            path="repaireandmaintainance"
            element={<RepairAndMaintanace />}
          />
          <Route path="hvlvtesting" element={<HVLvTesting />} />
          <Route path="rental" element={<Rental />} />
          <Route path="transformer1" element={<Transformer1/>} />
          <Route path="transformer2" element={<TransformerService2 />} />
          <Route path="transformer3" element={<TransformerService3 />} />
          <Route path="transformer4" element={<TransformerService4 />} />
          <Route path="transformer5" element={<TransformerService5 />} />
        </Route>

        <Route path="automation" element={<Automation />}>
          <Route path="automation1" element={<Automation2 />} />
          <Route path="automation2" element={<Automation2 />} />
          <Route path="automation3" element={<Automation2 />} />
          <Route path="automation4" element={<Automation2 />} />
          <Route path="automation5" element={<Automation2 />} />
        </Route>
        <Route path="contact" element={<Contact />} />

        <Route path="maintainance" element={<Maintainance />} />

        <Route path="*" element={<h1> 404 page not found</h1>} />
      </Route>
    </>
  )
);
root.render(
  <React.StrictMode>
     <Helmet>
      <title>JP Electrical And Power System</title>
      <meta name="description" content="Complete Solution For Electrical Engineering Needs." />
      <meta name="keywords" content="Electricals, JP Electricals, Engineering Works, Automation services, Transformer services" />
    </Helmet>
    <ToastContainer position="top-center"autoClose={2000}/>
    <RouterProvider router={router} />
  </React.StrictMode>
);
