import React from "react";
import SeviceCard from "./SeviceCard";
import styles from "./service.module.css";

import img1 from "../media/seviceCard/field services.jpg";
import img2 from "../media/seviceCard/Refurbishment and overhauling.jpg";
import img3 from "../media/seviceCard/Field Repairs & Maintenance.jpg";
import img4 from "../media/seviceCard/Hv & Lv Electrical Testing.jpg";

const Service = () => {
  return (
    <div className={`${styles.serviceParent} mt-8`}>
      <SeviceCard
        img={img1}
        title={"FIELD SERVICES"}
        desc={
          "Maintaining transformers for reliability, safety, and performance optimization..."
        }
        link={"transformer/fieldservice"}
      />
      <SeviceCard
        img={img2}
        title={" REFURBISHMENT AND OVERHAULING"}
        desc={"Renewing transformers, enhancing performance, ..."}
        link={"transformer/refurbishmentandoverhauling"}
      />
      <SeviceCard
        img={img3}
        title={" FIELD MAINTENANCE"}
        desc={"Providing essential upkeep and repairs for fields to ensure optimal function.."}
        link={"transformer/repaireandmaintainance"}
      />
      <SeviceCard
        img={img4}
        title={"  ELECTRICAL TESTING  service "}
        desc={"Offering comprehensive high voltage (HV) and low voltage (LV) testing services..."}
        link={"transformer/hvlvtesting"}
      />
    </div>
  );
};

export default Service;
