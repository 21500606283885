import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import serviceDetails from "../../data/services/serviceDetails.json";
import SingleCategory from "./SingleCategory";
const Categories = () => {
  const { id } = useParams();
  const service = serviceDetails[id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <div>
      <div className=" my-10  flex justify-center  w-full">
        {/* <hr className="max-w-6xl w-full" /> */}
        <div className=" max-w-6xl w-full text-sm  text-slate-500">
          <p>
            <Link className="hover:underline" to={"/"}>
              Home
            </Link>{" "}
            /{" "}
            <Link className="hover:underline" to={"/services"}>
              Services
            </Link>{" "}
            / {service?.name}
          </p>
        </div>
      </div>

      <h1 className="text-xl font-bold text-center text-slate-700">
        {service?.name}
      </h1>
      <div className=" flex justify-center">
        <p className="leading-6 text-slate-800 max-w-5xl mt-4 text-[15px]">
          {service?.description}
        </p>
      </div>

      {/* GridSection */}
      <div className="mt-12 flex flex-col gap-6 justify-center items-center">
        {/* SingleCategory */}

        {service?.categories?.map((category) => {
          return <SingleCategory key={category.name} category={category} />;
        })}

        {/* SingleCategory */}
      </div>
      {/* GridSection */}
    </div>
  );
};

export default Categories;
