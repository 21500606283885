import React from 'react'
import styles from './TransformerService3.module.css'
import tranformerImg from "../../media/NavTransformer/transformer1/transformer1.png";
import childImg from "../../media/NavTransformer/transformer1/child1.png";
import childCard1 from "../../media/NavTransformer/transformer1/cardChid1.png";

// service card
import serviceCardImg1 from "../../media/NavTransformer/transformer1/serviceCard1.png";


const TransformerService3 = () => {
  return (
    <div className={styles.container}>
    <div className={styles.imgage}>
      <img src={tranformerImg} alt="transformer" />
    </div>
    <div className={styles.child1}>
      <div className={styles.Child1Heading}>FIELD SERVICES  333</div>
      <div className={styles.child1Card}>
        <div className={styles.desc}>
          <p>
          At JP ELECTRICAL AND POWER SYSTEM, we pride ourselves on delivering
          tailored field services dedicated to guaranteeing the flawless
          operation and optimal performance of your transformers. Our team
          comprises highly skilled technicians equipped with the latest in
          cutting-edge technology, providing an extensive array of on-site
          services meticulously designed to meet your unique requirements.
          From precision installation and commissioning to routine
          </p>
          <p>            maintenance, diagnostic testing, repairs, and overhauls, our
          comprehensive suite of field services ensures that your transformers
          operate at peak efficiency. We go beyond standard offerings with
          specialized services such as oil filtration and purification. With a
          commitment to safety, reliability, and customized solutions, our
          field services stand as a testament to our dedication to enhancing
          the longevity and functionality of your transformers.</p>
        </div>
        <div className={styles.child1Img}>
          <img src={childImg} alt="child img" />
        </div>
      </div>
    </div>
    <div className={styles.cardsParent}>
      <div className={styles.cardHeading}>Our Services</div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.cardImg}>
            <img src={childCard1} alt="child card img" />
          </div>
          <p className={styles.cardTitle}> Installation & Commissioning</p>
          <p className={styles.cardDesk}>
            Our experienced technicians ensure accurate and efficient
            installation of transformers, adhering to industry standards and
            safety protocols.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardImg}>
            <img src={childCard1} alt="child card img" />
          </div>
          <p className={styles.cardTitle}> Installation & Commissioning</p>
          <p className={styles.cardDesk}>
            Our experienced technicians ensure accurate and efficient
            installation of transformers, adhering to industry standards and
            safety protocols.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardImg}>
            <img src={childCard1} alt="child card img" />
          </div>
          <p className={styles.cardTitle}> Installation & Commissioning</p>
          <p className={styles.cardDesk}>
            Our experienced technicians ensure accurate and efficient
            installation of transformers, adhering to industry standards and
            safety protocols.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardImg}>
            <img src={childCard1} alt="child card img" />
          </div>
          <p className={styles.cardTitle}> Installation & Commissioning</p>
          <p className={styles.cardDesk}>
            Our experienced technicians ensure accurate and efficient
            installation of transformers, adhering to industry standards and
            safety protocols.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardImg}>
            <img src={childCard1} alt="child card img" />
          </div>
          <p className={styles.cardTitle}> Installation & Commissioning</p>
          <p className={styles.cardDesk}>
            Our experienced technicians ensure accurate and efficient
            installation of transformers, adhering to industry standards and
            safety protocols.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardImg}>
            <img src={childCard1} alt="child card img" />
          </div>
          <p className={styles.cardTitle}> Installation & Commissioning</p>
          <p className={styles.cardDesk}>
            Our experienced technicians ensure accurate and efficient
            installation of transformers, adhering to industry standards and
            safety protocols.
          </p>
        </div>
      </div>
    </div>
    <div className={styles.ServicecardsParent}>
      <div className={styles.ServicecardHeading}>
        Why Choose Our Field Services?
      </div>
      <div className={styles.Servicecards}>
        <div className={styles.Servicecard}>
          <div className={styles.ServicecardImg}>
            <img src={serviceCardImg1} alt="service card img" />
          </div>
          <div className={styles.ServiceDesc}>
            <p className={styles.ServicecardTitle}>Expertise</p>
            <p className={styles.ServicecardDesk}>
              We prioritize precision in our services, ensuring reliability
              and adherence to industry best practices.
            </p>
          </div>
        </div>
        <div className={styles.Servicecard}>
          <div className={styles.ServicecardImg}>
            <img src={serviceCardImg1} alt="service card img" />
          </div>
          <div className={styles.ServiceDesc}>
            <p className={styles.ServicecardTitle}>Expertise</p>
            <p className={styles.ServicecardDesk}>
              We prioritize precision in our services, ensuring reliability
              and adherence to industry best practices.
            </p>
          </div>
        </div>
        <div className={styles.Servicecard}>
          <div className={styles.ServicecardImg}>
            <img src={serviceCardImg1} alt="service card img" />
          </div>
          <div className={styles.ServiceDesc}>
            <p className={styles.ServicecardTitle}>Expertise</p>
            <p className={styles.ServicecardDesk}>
              We prioritize precision in our services, ensuring reliability
              and adherence to industry best practices.
            </p>
          </div>
        </div>
        <div className={styles.Servicecard}>
          <div className={styles.ServicecardImg}>
            <img src={serviceCardImg1} alt="service card img" />
          </div>
          <div className={styles.ServiceDesc}>
            <p className={styles.ServicecardTitle}>Expertise</p>
            <p className={styles.ServicecardDesk}>
              We prioritize precision in our services, ensuring reliability
              and adherence to industry best practices.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.contact}>
      <p>Contact Us to Optimize Your Transformer Performance</p>
      <div className={styles.contactDesk}>
        <div className={styles.desc}>
          
          Transformer refurbishment and overhauling are essential for
          maintaining a reliable and efficient power distribution system. At
          JP ELECTRICAL AND POWER, we are committed to providing top-notch
          services that will extend the life of your transformers, ensuring
          uninterrupted power supply and cost savings. Contact us today to
          discuss your transformer refurbishment and overhauling needs and let
          us help you maximize the performance and longevity of your critical
          assets.
        </div>
        <div>
          <button>Contact Now</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TransformerService3