import React from "react";
import styles from "./Automation.module.css";

import headerImg from "../../media/automation/Automation header image.jpg";

// partners img
import partner1 from "../../media/automation/Automation/siemens.png";
import partner2 from "../../media/automation/Automation/schneider.png";
import partner3 from "../../media/automation/Automation/allen.png";
import partner4 from "../../media/automation/Automation/delta.png";
import plcAndDcs from "../../media/automation/plc and dcs.png";
import electronicSignal from "../../media/automation/electronic signal.jpg";
import signal from "../../media/automation/signal-isolator-500x500.webp";
import cell from "../../media/automation/Cell Voltage Monitoring System – CVMS.svg";
import auto0 from "../../media/automation/Automation/deltaPlc.png";
import auto1 from "../../media/automation/Automation/scada.png";
 

const Automation = () => {
  return (
    <>
     
      <div className={""}>
        <img src={headerImg} className="w-full" alt="image" />
      </div>
      <div className="flex justify-center px-4 md:px-10">
        <div className={"max-w-[1280px]"}>
          <div className={" mt-10 font-semibold text-lg text-center"}>
            <p className={""}>
              We provide specialized services in the Industrial automation field
              for various industry sectors. The main parts of our services are
              as follows:
            </p>
          </div>

          <div className={"mt-10 text-center text-lg md:text-xl font-semibold"}>
            <p className={""}>EXPERTISE IN PLC</p>
            <div className={" mt-8 flex justify-between gap-3 "}>
              <div className={""}>
                <img src={'/automation/SIEMENS.jpg'} className="rounded-lg" alt=" partner images" />
              </div>

              <div className={""}>
                <img src={'/automation/Schneider electric.jpg'} className="rounded-lg" alt=" partner images" />
              </div>

              <div className={""}>
                <img src={'/automation/DELTA.jpg'} className="rounded-lg" alt=" partner images" />
              </div>

              <div className={""}>
                <img src={'/automation/Allen-Bradley.jpg'} className="rounded-lg" alt=" partner images" />
              </div>
            </div>
          </div>

          <div
            className={
              "mt-20 flex flex-col md:flex-row gap-[30px] justify-between"
            }
          >
            <div className={"md:w-[60%] order-last md:order-first"}>
              <p className={"text-lg md:text-xl font-semibold"}>PLC and DCS</p>
              <ul className="mt-5 flex flex-col gap-2 leading-6">
                <li>
                  {" "}
                  Microverse PLC and DCS solutions offer superior reliability,
                  robustness, and precision for critical mission-control
                  automation.{" "}
                </li>
                <li>
                  {" "}
                  Microverse&#39;s software platform provides advanced control,
                  management, and comprehensive reporting capabilities.{" "}
                </li>
                <li>
                  {" "}
                  Function libraries include built-in algorithms for managing
                  various industries such as chemical, power plants, oil and
                  gas, metallurgy, and water management.
                </li>
                <li>
                  {" "}
                  Microverse DCS solutions offer centralized monitoring,
                  improved automation, advanced alarm management, historical
                  data logging, and remote access capabilities.{" "}
                </li>
                <li>
                  {" "}
                  These features enhance process efficiency, reliability,
                  safety, and productivity.{" "}
                </li>
              </ul>
            </div>

            <div className={"md:w-[40%]"}>
              <img
                src={plcAndDcs}
                className="lg:max-h-[700px] md:min-w-[40%] md:h-[350px]  w-full"
                alt="img1"
              />
            </div>
          </div>

          <div
            className={
              "mt-20 flex flex-col md:flex-row gap-[30px] justify-between"
            }
          >
            <div className={"md:w-[60%] order-last md:order-first"}>
              <p className={"text-lg md:text-xl font-semibold"}>
                Electronic Supervisor (patented) for Batch Process Management
              </p>
              <ul className="mt-5 flex flex-col gap-2 leading-6">
                <li>
                  {" "}
                  The Electronic Supervisor is a patented tool designed to
                  enhance productivity in chemical batch process plants.{" "}
                </li>
                <li>
                  It ensures the precise execution and logging of both manual
                  and automated steps according to a recipe or standard
                  operating procedure (SOP).
                </li>
                <li>
                  Operators are guided through manual steps via voice
                  announcements, ensuring compliance, and logging activities for
                  accountability.
                </li>
                <li>
                  After each batch, a detailed report is automatically
                  generated, documenting the status at each step completion,
                  quality control (QC) and lab parameters, any process upsets,
                  and deviations in time for each step.
                </li>
                <li>
                  By enforcing strict process compliance and consistency, the
                  Electronic Supervisor significantly improves batch cycle times
                  and product quality.
                </li>
              </ul>
            </div>

            <div className={"md:w-[40%]"}>
              <img
                src={electronicSignal}
                alt="img1"
                className="md:h-[350px] md:min-w-[40%]   w-full"
              />
            </div>
          </div>

          <div
            className={
              "mt-20 flex flex-col md:flex-row gap-[30px] justify-between"
            }
          >
            <div className={"md:w-[60%] order-last md:order-first"}>
              <p className={"text-lg md:text-xl font-semibold"}>
                Signal Isolators and Termination Modules
              </p>
              <ul className="mt-5 flex flex-col gap-2 leading-6">
                <li>
                  Signal isolators are essential for protecting delicate
                  equipment like PLCs, DCS systems, and data loggers/scanners
                  from damaging transients and ground-loop currents originating
                  from the field.
                </li>
                <li>
                  They also prevent high voltages and currents from reaching the
                  field in case of faults in control room equipment. This is
                  crucial, especially in hazardous areas where strict limits on
                  current and voltage levels are necessary for safety.
                </li>
                <li>
                  Microverse has developed termination modules that ensure
                  safety, easy maintenance, and protection for PLCs and DCS
                  systems by safeguarding them against interference in field
                  signals, including EMI and transient protection, meeting IEC
                  61000-4-4 standards.
                </li>

                <li>
                  CVMS provides crucial data insights to optimize battery
                  management strategies, maximizing efficiency and longevity.
                </li>

                <li>
                  The Cell Voltage Monitoring System (CVMS) ensures real-time
                  monitoring of individual cell voltages for enhanced safety and
                  performance in battery systems.
                </li>
              </ul>
            </div>

            <div className={"md:w-[40%]"}>
              <img
                src={signal}
                className=" max-h-[450px] md:h-[400px] md:min-w-[40%]   w-full"
                alt="img1"
              />
            </div>
          </div>

          <div
            className={
              "mt-20 flex flex-col md:flex-row gap-[30px] justify-between"
            }
          >
            <div className={"md:w-[60%] order-last md:order-first"}>
              <p className={"text-lg md:text-xl font-semibold"}>
                Cell Voltage Monitoring System – CVMS
              </p>
              <ul className="mt-5 flex flex-col gap-2 leading-6">
                <li>
                  The Cell Voltage Monitoring System (CVMS) is a critical tool
                  utilized in Electrolytic Refining plants for various metals
                  like Copper, Zinc, Nickel, and in Chlorination plants.
                </li>
                <li>
                  In these plants, multiple electrolytic cells are linked in a
                  series. CVMS keeps track of the voltage across each individual
                  cell and triggers alarms if there are instances of under or
                  over voltage. Under voltage signals a potential short circuit,
                  while over voltage could indicate issues like poor connections
                  or passivation. Additionally, CVMS monitors current and
                  totalized ampere-hours, providing insights into the condition
                  of the yield.
                </li>
                <li>
                  Microverse has developed termination modules that ensure
                  safety, easy maintenance, and protection for PLCs and DCS
                  systems by safeguarding them against interference in field
                  signals, including EMI and transient protection, meeting IEC
                  61000-4-4 standards.
                </li>
              </ul>
            </div>

            <div className={"md:w-[40%]"}>
              <img
                src={cell}
                alt="img1"
                className=" md:min-w-[40%] w-full md:h-[350px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Automation;
