import { Link, useNavigate } from "react-router-dom";
import style from "./SimilarProduct.module.css";
import Enquire from "../enquiry/Enquire";
const SimilarProduct = ({ cat }) => {
  const navigate = useNavigate();
  return (
    <div className={style.card}>
      <img
        onClick={() => navigate(`/services/details/${cat.id}`)}
        className={`${style.productImage} cursor-pointer`}
        src={cat.img}
        alt="product image"
      />
      <Link to={`/services/details/${cat.id}`} className="text-center">
        {cat.name}
      </Link>
      <p className="flex justify-center">
        <Enquire className={'bg-indigo-900 hover:bg-indigo-700 px-4 py-2 rounded-md text-white font-medium'} productName={cat.name}/>
      </p>
    </div>
  );
};

export default SimilarProduct;
